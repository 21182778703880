import React from "react";

import { HeaderPosition } from "components/calendar/PositionGrid/models";
import PositionGrid from "components/calendar/PositionGrid/PositionGrid";
import PositionGridHeader from "components/calendar/PositionGrid/PositionGridHeader";
import TimeGridHeader from "components/calendar/TimeGrid/TimeGridHeader";
import { weekOfYear } from "commons/time";

export interface TimeGridAxis {
    size: number;
    headers: React.ReactElement[];
}


const Days = ["Mon", "Wed", "Fri"];
const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const createAxis = (
    type: "day-of-week" | "months",
    axis: "x" | "y",
    selection: [Date, Date],
): TimeGridAxis => {
    if (type === "day-of-week") {
        const position = (i: number): HeaderPosition =>
            axis === "x"
                ? [(i + 1) * 2 + 1, (i + 1) * 2]
                : [(i + 1) * 2, (i + 1) * 2 + 1]
        return {
            size: 7,
            headers: Days.map((day, i) =>
                <PositionGridHeader
                    key={`${axis}-axis-header-${i}`}
                    axis={axis}
                    position={position(i)}
                >
                    <TimeGridHeader
                        TypographyProps={{
                            variant: "body1",
                            fontFamily: "Roboto Mono",
                        }}
                    >
                        {day}
                    </TimeGridHeader>
                </PositionGridHeader>
            ),
        }
    }

    if (type === "months") {
        const position = (i: number): HeaderPosition => [
            weekOfYear(new Date(selection[0].getFullYear(), i, 1)),
            weekOfYear(new Date(selection[0].getFullYear(), i + 1, -1)),
        ]
        return {
            // size: 53,
            size: weekOfYear(selection[1]) - weekOfYear(selection[0]) + 1,
            headers: Months.slice(selection[0].getMonth(), selection[1].getMonth() + 1)
                .map((month, i) =>
                    <PositionGridHeader
                        key={`${axis}-axis-header-${i}`}
                        axis={axis}
                        position={position(i)}
                    >
                        <TimeGridHeader>
                            {month}
                        </TimeGridHeader>
                    </PositionGridHeader>
                )
        }
    }
    return { size: 0, headers: [] };
}


const TimeGrid = ({
    xAxis: xAxisType,
    yAxis: yAxisType,
    selection,
    maxWidth,
    maxHeight,
    children,
}: TimeGridProps) => {
    if (!selection) {
        const today = new Date();
        selection = [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31),
        ]
    }

    const xAxis = createAxis(xAxisType, "x", selection);
    const yAxis = createAxis(yAxisType, "y", selection);

    return (
        <PositionGrid
            columns={xAxis.size}
            rows={yAxis.size}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
        >
            {xAxis.headers}
            {yAxis.headers}
            {children}
        </PositionGrid>
    )
}

export interface TimeGridProps {
    xAxis: "months";
    yAxis: "day-of-week";
    unit: "date";
    maxWidth?: number | string;
    maxHeight?: number | string;

    selection?: [Date, Date];
    onSelectionChanged?: (selection: [Date, Date]) => void;

    children?: React.ReactNode;
}

export default TimeGrid;