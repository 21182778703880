import { New } from "commons";

export type Mood = 1 | 2 | 3 | 4 | 5;

export type Emotion = "happy" | "sad" | "shame";
export interface MoodieDailyMood {
    id: string;
    timestamp: Date;
    mood?: Mood;
}

export const newDailyMood = (timestamp?: Date): New<MoodieDailyMood> => ({
    timestamp: timestamp || new Date(),
})