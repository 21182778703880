import { AnyAction } from '@reduxjs/toolkit'

import { deserializer, serializer, New } from "commons/models";
import { indexAddRecord, indexRemoveRecord, ReduxIndex } from "commons/redux";
import { randomKey } from "commons/utils";
import { Note, NoteStyle } from "models";

export interface NoteState {
    id: string;
    title: string;
    content: string;
    style?: NoteStyle;
    createdAt: string;
    updatedAt: string;
}

export const serialize = serializer<Note, NoteState>((note) => ({
    title: note.title,
    content: note.content,
    style: note.style,
    createdAt: note.createdAt.toISOString(),
    updatedAt: note.updatedAt.toISOString(),
}));
export const deserialize = deserializer<Note, NoteState>((state) => ({
    title: state.title,
    content: state.content,
    style: state.style,
    createdAt: new Date(state.createdAt),
    updatedAt: new Date(state.updatedAt),
}));

interface AddAction {
    type: "NOTE_ADD";
    payload: NoteState;
}

interface UpdateAction {
    type: "NOTE_UPDATE";
    payload: NoteState;
}

interface DeleteAction {
    type: "NOTE_DELETE";
    payload: {
        id: string;
    };
}

export type NoteAction = AddAction | UpdateAction | DeleteAction;

export const matchNoteAction = (action: AnyAction): action is NoteAction =>
    action.type === "NOTE_ADD"
    || action.type === "NOTE_UPDATE"
    || action.type === "NOTE_DELETE";

export const addNote = (note: Note): AddAction => {
    return {
        type: "NOTE_ADD",
        payload: serialize(note),
    }
}

export const insertNote = (note: New<Note>): UpdateAction => ({
    type: "NOTE_UPDATE",
    payload: serialize({
        id: randomKey(),
        ...note,
    })
})

export const updateNote = (note: Note): UpdateAction => {
    return {
        type: "NOTE_UPDATE",
        payload: serialize(note),
    }
}

export const deleteNote = (id: string): DeleteAction => {
    return {
        type: "NOTE_DELETE",
        payload: { id },
    }
}

export const NoteReducer = (state: ReduxIndex<NoteState>, action: AnyAction): ReduxIndex<NoteState> => {
    if (!matchNoteAction(action)) {
        return state;
    }

    switch (action.type) {
        case "NOTE_ADD":
        case "NOTE_UPDATE":
            return indexAddRecord(state, action.payload.id, action.payload);

        case "NOTE_DELETE":
            return indexRemoveRecord(state, action.payload.id);

        default:
            return state;
    }
}


