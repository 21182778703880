import { useDispatch } from "react-redux";

import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { Dialog } from "components-lib/layout";
import { DiaryQueries } from "state/moodie";


const MoodieDiaryRecordDeleteDialog = ({ id, open, onClose }: Props) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(DiaryQueries.Record.Delete.once(id));
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} fullScreen={false}>
            <DialogTitle>Delete Note</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this record?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}> Cancel </Button>
                <Button onClick={handleDelete}
                        color="error">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
};

interface Props {
    id: string;

    open: boolean;
    onClose: () => void;
}

export default MoodieDiaryRecordDeleteDialog;