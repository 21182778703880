const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

type DateLike = Date | string;

export function parseDateLike(date: DateLike): Date {
    if (date instanceof Date) {
        // already a date
        return date;
    }
    return new Date(date);
}


export function monthName(monthOrDate?: DateLike | number) {
    if (typeof monthOrDate === "number") {
        return months[monthOrDate];
    }
    if (monthOrDate === undefined) {
        monthOrDate = new Date();
    }
    const date = parseDateLike(monthOrDate);
    return months[date.getMonth()];
}

export function monthDate(dateLike: DateLike) {
    const date = parseDateLike(dateLike);
    return date.getDate();
}

export function today() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}


interface PrettyTimestampOptions {
    short?: boolean;
}

interface PrettyTimestampDateFormatOptions extends PrettyTimestampOptions {

}

interface PrettyTimestampTimeFormatOptions extends PrettyTimestampOptions {

}


interface PrettyTimestampDateTimeFormatOptions extends PrettyTimestampOptions {

}

interface PrettyTimestampCompareOptions extends PrettyTimestampOptions {

}


interface PrettyTimestamp {
    (type: "date", date: Date, options?: PrettyTimestampDateFormatOptions): string;

    (type: "time", date: Date, options?: PrettyTimestampTimeFormatOptions): string;

    (type: "datetime", date: Date, options?: PrettyTimestampDateTimeFormatOptions): string;

    (type: "compare", date: Date, other: Date, options?: PrettyTimestampCompareOptions): string;
}

export const prettyTimestampDate = (date: Date, options: PrettyTimestampDateFormatOptions = {}) => {
    return date.toLocaleDateString();
}

export const prettyTimestampTime = (date: Date, options: PrettyTimestampDateFormatOptions = {}) => {
    return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    });
}


export const prettyTimestampDateTime = (date: Date, options: PrettyTimestampDateTimeFormatOptions = {}) => {
    return date.toLocaleString([], {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });
}

export const prettyTimestampCompare = (date: Date, other: Date, options: PrettyTimestampCompareOptions = {}) => {
    const diff = Math.abs(date.getTime() - other.getTime()) / 1000;

    if (diff < 60) {
        return options.short ? "now" : "just now"
    } else if (diff < 120) {
        return options.short ? "1m" : "a minute ago"
    } else if (diff < 3600) {
        const minutes = Math.floor(diff / 60);
        return options.short ? `${minutes}m` : `${minutes} minutes ago`
    } else if (diff < 7200) {
        return options.short ? "1h" : "an hour ago"
    } else if (diff < 84600) {
        const hours = Math.floor(diff / 3600);
        return options.short ? `${hours}h` : `${hours} hours ago`
    } else if (diff < 172800) {
        return options.short ? "1d" : "a day ago"
    } else {
        const days = Math.floor(diff / 86400);
        return options.short ? `${days}d` : `${days} days ago`
    }
}

export const prettyTimestamp = ((
    type: "date" | "time" | "datetime" | "compare",
    date: Date,
    ...args: any
) => {
    if (type === "date") {
        const options: PrettyTimestampDateFormatOptions = args[0] || {};
        return prettyTimestampDate(date, options);
    }

    if (type === "time") {
        const options: PrettyTimestampTimeFormatOptions = args[0] || {};
        return prettyTimestampTime(date, options);
    }

    if (type === "datetime") {
        const options: PrettyTimestampDateTimeFormatOptions = args[0] || {};
        return prettyTimestampDateTime(date, options);
    }

    if (type === "compare") {
        const other: Date = args[0];
        const options: PrettyTimestampCompareOptions = args[1] || {};
        return prettyTimestampCompare(date, other, options);
    }
}) as PrettyTimestamp

export const useToday = () => {
    // TODO(souperk): figure out how this should work so that today is changed every day
    return today();
}

export const useNow = (updateInterval: number = 1) => {
    // TODO(souperk):
    return new Date();
}
