import { dayOfWeek, weekOfYear } from "commons/time";
import React from "react";

import PositionGridCell from "components/calendar/PositionGrid/PositionGridCell";

const calculatePosition = (
    date: Date,
    xAxis: "months",
    yAxis: "day-of-week",
    selection: [Date, Date],
) => {
    const day = dayOfWeek(date);
    const week = weekOfYear(date) - weekOfYear(selection[0]) + 1;
    return [week, day + 1];
}


const TimeGridCell = React.forwardRef(({ date, selection, children, ...other }: TimeGridCellProps, ref) => {
    const [x, y] = calculatePosition(date, "months", "day-of-week", selection);
    return (
        <PositionGridCell
            ref={ref}
            position={[x, y]}
            {...other}
        >
            {children}
        </PositionGridCell>

    )
})

export interface TimeGridCellProps {
    date: Date;
    selection: [Date, Date];
    children?: React.ReactNode;
}

export default TimeGridCell;