import { useState } from "react";

import {
    Box,
    CircularProgress,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";

import { isReady, resource } from "commons";
import { FilterAltIcon, SearchIcon } from "components-lib/icons";
import { Collage, CollageItem } from "components-lib/layout";
import { useDiaryRecords } from "state/moodie";

import MoodieDiaryRecordPreview from "./MoodieDiaryRecordPreview";

const MoodieDiary = () => {
    const [search, setSearch] = useState("");
    const [filtersVisible, setFiltersVisible] = useState(false);

    const query = useDiaryRecords("moodie-diary", { search });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <Typography variant="h4">
                Diary
            </Typography>
            <Toolbar sx={{ paddingX: 0 }}>
                <TextField
                    value={search}
                    onChange={event => setSearch(event.target.value)}
                    placeholder="Search ..."
                    size="medium"
                    InputProps={{ startAdornment: <SearchIcon/> }}
                    sx={{ flexGrow: 1 }}
                />
                <IconButton aria-label="show-filters" onClick={() => setFiltersVisible(!filtersVisible)}>
                    <FilterAltIcon/>
                </IconButton>
            </Toolbar>
            {!isReady(query) && (
                <Box sx={{ display: "flex", justifyContent: "center", padding: [1, 2] }}>
                    <CircularProgress/>
                </Box>
            )}
            {isReady(query) && (
                <Collage minItemWidth="min(80vw, 30rem)">
                    {resource(query).map(record =>
                        <CollageItem key={record.id}>
                            <MoodieDiaryRecordPreview record={record}/>
                        </CollageItem>
                    )}
                </Collage>
            )}
        </Box>
    )
}


export default MoodieDiary;

