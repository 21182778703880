import { AnyAction } from "@reduxjs/toolkit";
import { BaseModel } from "commons/models";
import { Serializer } from "commons/models/Serializer";

export interface ReduxIndex<T> {
    [id: string]: T;
}

export const buildIndex = <T>(items: T[], key: (item: T) => string): ReduxIndex<T> => {
    return items.reduce((index, item) => {
        index[key(item)] = item;
        return index;
    }, {} as ReduxIndex<T>);
}

export const indexAddRecord = <T>(index: ReduxIndex<T>, id: string, record: T): ReduxIndex<T> =>
    ({
        ...index,
        [id]: record,
    })

export const indexRemoveRecord = <T>(index: ReduxIndex<T>, id: string): ReduxIndex<T> =>
    Object.keys(index)
        .filter(x => x !== id)
        .reduce<ReduxIndex<T>>((acc, key) => {
            acc[key] = index[key];
            return acc;
        }, {})

export interface ReduxIndexAddAction<State> {
    type: string;
    payload: State;
}

export interface ReduxIndexRemoveAction {
    type: string;
    payload: {
        id: string;
    }
}

export type ReduxIndexAction<State> =
    ReduxIndexAddAction<State>
    | ReduxIndexRemoveAction
    ;

const isAddAction = <State>(action: AnyAction, prefix: string): action is ReduxIndexAddAction<State> =>
    action.type === `${prefix}_ADD`;

const isRemoveAction = (action: AnyAction, prefix: string): action is ReduxIndexRemoveAction =>
    action.type === `${prefix}_REMOVE`;


export const createIndex = <Model extends BaseModel, State extends BaseModel>(
    prefix: string,
    serialize: Serializer<Model, State>,
) => {
    const AddActionType = `${prefix}_ADD`;
    const RemoveActionType = `${prefix}_REMOVE`;

    const match = (action: AnyAction): action is ReduxIndexAction<State> =>
        isAddAction(action, prefix) || isRemoveAction(action, prefix);

    const reducer = (state: ReduxIndex<State> = {}, action: AnyAction): ReduxIndex<State> => {
        if (isAddAction<State>(action, prefix)) {
            return indexAddRecord<State>(state, action.payload.id, action.payload);
        }

        if (isRemoveAction(action, prefix)) {
            return indexRemoveRecord(state, action.payload.id);
        }

        return state;
    }

    return {
        reducer,
        match,
        actions: {
            add: (model: Model): ReduxIndexAddAction<State> => ({
                type: AddActionType,
                payload: serialize(model),
            }),
            remove: (id: string): ReduxIndexRemoveAction => ({
                type: RemoveActionType,
                payload: {
                    id,
                },
            })
        }
    }
}