import { useSelector } from "react-redux";

interface ToggleModeAction {
    type: "THEME_MODE_TOGGLE"
}

interface SetModeAction {
    type: "THEME_SET_MODE";
    payload: {
        mode: "dark" | "light";
    }
}

type ThemeAction = ToggleModeAction | SetModeAction;

interface ThemeState {
    mode: 'dark' | 'light';
}

export const toggle = (): ToggleModeAction => ({ type: "THEME_MODE_TOGGLE" });
export const setMode = (mode: "dark" | "light"): SetModeAction => ({ type: "THEME_SET_MODE", payload: { mode } })

export const ThemeReducer = (state: ThemeState = { mode: 'dark' }, action: ThemeAction): ThemeState => {
    switch (action.type) {
        case "THEME_MODE_TOGGLE":
            return {
                ...state,
                mode: state.mode === "dark" ? "light" : "dark",
            };

        case "THEME_SET_MODE":
            return {
                ...state,
                mode: action.payload.mode,
            }

        default:
            return state;
    }
}

export const useThemeMode = (): "dark" | "light" => {
    return useSelector((state: any) => state.theme.mode);
}