import React from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    BoxProps,
    styled,
    SvgIconProps,
    Typography,
    useTheme
} from "@mui/material";

import { SpaIcon, TextSnippetIcon } from "components-lib/icons";

interface App {
    name: string;
    color: string;
    link: string;
    icon?: React.ComponentType<SvgIconProps>;
}

const applications: App[] = [
    { name: 'Tasks', color: '#FDD563', link: '/tasks' },
    { name: 'Moodie', color: '#8BE38B', link: '/moodie', icon: SpaIcon },
    { name: 'Notes', color: '#B3B4F7', link: '/notes', icon: TextSnippetIcon },
];

const AppsWidget = (): JSX.Element => {
    const navigate = useNavigate()
    return (
        <AppsWrapper>
            {applications.map((item, index) => (
                <ApplicationWrapper
                    key={index}
                    sx={{ borderColor: item.color }}
                    onClick={() => navigate(item.link)}>
                    <AppIcon color={item.color} icon={item.icon}/>
                    <Typography variant="h4" component="span">
                        {item.name}
                    </Typography>
                </ApplicationWrapper>
            ))}
        </AppsWrapper>
    );
};

const AppsWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
}))

const ApplicationWrapper = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",

    display: "flex",
    alignItems: "center",

    gap: theme.spacing(2),
    padding: theme.spacing(1, 2),

    border: 2,
    borderStyle: "solid",
    borderRadius: 12,

    cursor: "pointer",

    '&:hover': {
        boxShadow: theme.shadows[8],
        backgroundColor: theme.palette.action.hover,
    }
}))

const AppIcon = ({
    icon: Icon,
    color,
    ...props
}: BoxProps & { icon?: React.ComponentType<SvgIconProps>, color: string }) => {
    const theme = useTheme();

    return (
        <Box sx={{
            width: 64,
            height: 64,
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: color,
        }} {...props}>
            {Icon && <Icon
                fontSize="large"
                sx={{
                    color: theme.palette.getContrastText(color)
                }}/>}
        </Box>
    )
}

export default AppsWidget;