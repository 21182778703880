import { BaseModel, isPersisted, New } from "commons/models/BaseModel";

export interface Deserializer<Model extends BaseModel, State extends BaseModel> {
    (state: State): Model;

    (state: New<State>): New<Model>;
}

export const deserializer = <Model extends BaseModel, State extends BaseModel>(
    deserializer: (state: New<State>) => New<Model>
): Deserializer<Model, State> => (state => {
    const model = deserializer(state);
    if (isPersisted(state)) {
        // @ts-ignore
        model.id = state.id
    }
    return model;
}) as Deserializer<Model, State>;