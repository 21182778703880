import { useBreakpoints } from "commons/media";
import React, { useMemo, useState } from "react";

import { Box, IconButton, Typography } from "@mui/material";

import {
    TimeGrid,
    TimeGridCell,
    utcAddDays,
    utcAddMonths,
    utcAddYears,
    utcDateEquals,
    utcRange,
    utcYear
} from "components/calendar/TimeGrid";
import { ArrowBackIcon, ArrowForwardIcon } from "components-lib/icons";
import { useLogs } from "state/moodie";

import MoodieDailyCalendarItem from "./MoodieDailyCalendarItem";

const MoodieDailyCalendar = () => {
    const isCompact = useBreakpoints(true, true, true, false);
    const logs = useLogs();

    let [stateStart, setStart] = useState(utcYear);
    const start = isCompact ? stateStart : utcYear(stateStart);
    const end = utcAddDays(isCompact ? utcAddMonths(start, 3) : utcAddYears(start, 1), -1)

    const dates = useMemo(() => utcRange(start, end), [start, end]);
    const cells = dates.map(date => {
        const key = `cell-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        const log = logs.find(x => utcDateEquals(x.timestamp, date));
        return (
            <TimeGridCell key={key} date={date} selection={[start, end]}>
                <MoodieDailyCalendarItem date={date} mood={log || null}/>
            </TimeGridCell>
        )
    });

    const goForward = () => setStart(
        isCompact ? utcAddMonths(start, 3) : utcAddYears(start, 1)
    )
    const goBack = () => setStart(
        isCompact ? utcAddMonths(start, -3) : utcAddYears(start, -1)
    )

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isCompact ? "space-between" : "center",
                    gap: 2,
                }}
            >
                <IconButton onClick={goBack}>
                    <ArrowBackIcon/>
                </IconButton>
                <Typography>
                    {start.getFullYear()}
                </Typography>
                <IconButton onClick={goForward}>
                    <ArrowForwardIcon/>
                </IconButton>
            </Box>
            <TimeGrid
                xAxis="months"
                yAxis="day-of-week"
                unit="date"
                selection={[start, end]}
            >
                {cells}
            </TimeGrid>
        </Box>
    )
}

export default MoodieDailyCalendar;