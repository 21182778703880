import React, { useEffect, useRef } from "react";

import { SxProps } from "@mui/system";
import { Box, BoxProps, Button, ButtonGroup, IconButton, TextField, Theme } from "@mui/material";

import { useHistory } from "commons/hooks";
import { Operation, Operations } from "commons/markdown";
import { useDevice } from "commons/media";
import Markdown from "components-lib/Markdown";

import {
    CodeIcon,
    FormatBoldIcon,
    FormatItalicIcon,
    FormatListBulletedIcon,
    FormatListNumberedIcon, FormatQuoteIcon
} from "components-lib/icons";


const MarkdownEditor = ({
    value,
    mode = "source",
    selectionStart = 0,
    selectionEnd = 0,
    flex = false,
    onPreview,
    onSource,
    onChange,
    onSelection,
    background,
    text,
}: TextEditorProps): JSX.Element => {
    // calculate default rows
    const { deviceType } = useDevice();


    // TODO(souperk): support undo (ctrl + z) for actions
    const elementRef = useRef<HTMLInputElement>(null);

    const history = useHistory({ text: value, selectionStart, selectionEnd });

    function apply(op: Operation) {
        if (!elementRef.current) {
            return;
        }
        const textarea: HTMLTextAreaElement | undefined = Array.from(
            elementRef.current.getElementsByTagName("textarea")
        ).find(x => x.style.visibility !== "hidden");
        if (!textarea) {
            return;
        }
        const result = op(textarea);
        if (result !== undefined) {
            history.push({
                text: textarea.value,
                selectionStart: textarea.selectionStart,
                selectionEnd: textarea.selectionEnd,
            });
            onChange(result.text);
            onSelection(result.selectionStart, result.selectionEnd);
        }
    }

    function editorAction(op: Operation) {
        return (e: React.MouseEvent) => {
            apply(op);
            e.preventDefault();
        }
    }

    // TODO(souperk): convert these to shortcuts
    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.shiftKey && e.code === "Tab") {
            e.preventDefault();
            apply(Operations.DeIndent);
        } else if (e.code === "Tab") {
            e.preventDefault();
            apply(Operations.Indent);
        }

        if (e.ctrlKey && e.shiftKey && e.code === "KeyZ") {
            e.preventDefault();
            const state = history.redo();
            onChange(state.text);
            onSelection(state.selectionStart, state.selectionEnd);
        } else if (e.ctrlKey && e.code === "KeyZ") {
            e.preventDefault();
            const state = history.undo();
            onChange(state.text);
            onSelection(state.selectionStart, state.selectionEnd);
        }

        if (e.ctrlKey && e.code === "KeyB") {
            e.preventDefault();
            apply(Operations.Bold);
        }

        if (e.ctrlKey && e.code === "KeyI") {
            e.preventDefault();
            apply(Operations.Italic);
        }
    }

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        history.push({
            text: value,
            selectionStart,
            selectionEnd,
        });
        onChange(e.target.value);
        onSelection(e.target.selectionStart, e.target.selectionEnd);
    }

    const handleOnSource = () => {
        if (mode !== "source") {
            onSource();
        }
    }

    const handleOnPreview = () => {
        if (mode !== "preview") {
            onPreview();
        }
    }

    useEffect(() => {
        if (!elementRef.current) {
            return;
        }
        const textarea: HTMLTextAreaElement | undefined = Array.from(
            elementRef.current.getElementsByTagName("textarea")
        ).find(x => x.style.visibility !== "hidden");
        if (!textarea) {
            return;
        }
        textarea.setSelectionRange(selectionStart, selectionEnd);
    }, [selectionStart, selectionEnd, value.length]);

    return (
        <TextEditorRoot mode={deviceType} flex={flex}>
            <TextEditorFormatOptions viewMode={deviceType}>
                <ButtonGroup>
                    <IconButton onPointerDown={editorAction(Operations.Bold)}>
                        <FormatBoldIcon/>
                    </IconButton>
                    <IconButton onPointerDown={editorAction(Operations.Italic)}>
                        <FormatItalicIcon/>
                    </IconButton>
                </ButtonGroup>
                <ButtonGroup>
                    <IconButton onPointerDown={editorAction(Operations.UnorderedList)}>
                        <FormatListBulletedIcon/>
                    </IconButton>
                    <IconButton onPointerDown={editorAction(Operations.OrderedList)}>
                        <FormatListNumberedIcon/>
                    </IconButton>
                </ButtonGroup>
                <ButtonGroup>
                    <IconButton onPointerDown={editorAction(Operations.Code)}>
                        <CodeIcon/>
                    </IconButton>
                    <IconButton onPointerDown={editorAction(Operations.BlockQuotes)}>
                        <FormatQuoteIcon/>
                    </IconButton>
                </ButtonGroup>
            </TextEditorFormatOptions>
            <TextEditorBody viewMode={deviceType} style={{ backgroundColor: background, color: text }}>
                {mode === "source" &&
                    <TextField
                        ref={elementRef}
                        multiline
                        variant="outlined"
                        value={value}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            sx: {
                                fontFamily: "Roboto Mono",
                            }
                        }}
                        sx={{
                            ".MuiInputBase-root": {
                                flex: 1,
                                alignItems: "flex-start",
                                padding: 0,
                            },
                            "& fieldset": {
                                border: "none",
                            },
                        }}
                    />
                }
                {mode === "preview" && <Markdown text={value}/>}
            </TextEditorBody>
            <TextEditorViewMode viewMode={deviceType}>
                {mode === "preview" && <Button
                    onClick={handleOnSource}
                    sx={{ flexGrow: 1 }}
                    color="inherit"
                >
                    Source
                </Button>}

                {mode === "source" && <Button
                    onClick={handleOnPreview}
                    sx={{ flexGrow: 1 }}
                    color="inherit"
                >
                    Preview
                </Button>}
            </TextEditorViewMode>
        </TextEditorRoot>
    );
}


const TextEditorRoot = ({
    mode,
    flex,
    children
}: { mode: "desktop" | "mobile", flex: boolean, children: React.ReactNode }) => {
    const borderColor =
        (theme: Theme) => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)';
    let style: SxProps<Theme> = {
        boxSizing: "border-box",
        overflow: "auto",
        borderColor,
    }
    if (flex) {
        style = {
            ...style,
            flex: 1,
        }
    }

    if (mode === "desktop" || mode === "mobile") {
        style = {
            ...style,
            display: "grid",
            gridTemplateAreas: '"format-options view-mode" "body body"',
            gridTemplateRows: "auto 1fr",
            gridTemplateColumns: "1fr auto",
            borderTop: theme => `1px solid ${borderColor(theme)}`,
            borderBottom: theme => `1px solid ${borderColor(theme)}`,
            borderColor,
        }
    }

    return (
        <Box sx={style}>
            {children}
        </Box>
    )
}

const TextEditorFormatOptions = ({ viewMode, ...props }: BoxProps & { viewMode: "mobile" | "desktop" }) => {

    let style = {};
    if (viewMode === "mobile") {
        style = {
            ...style,
            borderTop: 1,
            borderBottom: 1,
            borderColor: "inherit",
        }
    }

    return <Box {...props} sx={{
        gridArea: "format-options",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        gap: 2,
        backgroundColor: "background",
        ...style,
    }}/>
}

const TextEditorBody = ({ viewMode, ...props }: BoxProps & { viewMode: "mobile" | "desktop" }) => {
    let style = {};
    if (viewMode === "desktop") {
        style = {
            ...style,
            borderTop: 1,
            borderBottom: 1,
            borderColor: "inherit"
        }
    }
    return <Box {...props} sx={{
        gridArea: "body",
        boxSizing: "border-box",
        display: "grid",
        gridTemplateAreas: '"content"',
        // we need to force "width: 0" on content
        // because otherwise markdown gets wild
        gridTemplateColumns: "minmax(0, 1fr)",
        overflow: "auto",
        padding: theme => theme.spacing(1, 2),
        ...style,
    }}/>
}

const TextEditorViewMode = ({ viewMode, ...props }: BoxProps & { viewMode: "mobile" | "desktop" }) => {
    let style = {};
    if (viewMode === "mobile") {
        style = {
            ...style,
            borderTop: 1,
            borderBottom: 1,
            borderColor: "inherit",
        }
    } else if (viewMode === "desktop") {
        style = {
            ...style,
        }
    }


    return <Box {...props} sx={{
        gridArea: "view-mode",
        boxSizing: "border-box",
        display: "inline-flex",
        ...style,
    }}/>
}

interface TextEditorProps {
    value: string;
    mode: 'source' | 'preview';
    selectionStart: number;
    selectionEnd: number;

    flex?: boolean;

    onChange: (value: string) => void;
    onPreview: () => void;
    onSource: () => void;
    onSelection: (start: number, end: number) => void;

    background?: string;
    text?: string;
}

export default MarkdownEditor;
