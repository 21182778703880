import { Mood } from "models";
import { mix, setLightness, setSaturation } from "polished";

// TODO(souperk): figure out why we use baseColor and document it
export const baseColor = (color: string) =>
    setSaturation(1, setLightness(0.5, color));

export const VeryGoodMoodColor = "#80FF00"
export const VeryBadMoodColor = "#FF0000";

export const MoodGradient = [0, 0.25, 0.5, 0.75, 1].map(
    weight => mix(
        weight,
        baseColor(VeryGoodMoodColor),
        baseColor(VeryBadMoodColor),
    )
);


export const moodGradient = (mood: Mood) => MoodGradient[mood - 1];

export const MoodieColors = {
    emotions: {
        "happy": "#BAEDA9",
        "shame": "#880808",
    },
}

export function emotionColor(emotion: string): string | undefined;
export function emotionColor(emotion: string, defaultValue: string): string;
export function emotionColor(emotion: string, defaultValue?: string) {
    if (!(emotion in MoodieColors.emotions)) {
        return defaultValue;
    }

    // @ts-ignore this guarded  by the if statement,
    // compiler does not recognise that
    return MoodieColors.emotions[emotion];
}