import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
    ReduxQueryIndex,
    ResourceError,
    ResourceFailed, ResourceReady,
    ResourceRequested
} from "commons/models";

export class LorythAPI {

    private token: string | null = null;

    constructor(
        private url: string,
    ) {
    }

    authenticate(token: string) {
        this.token = token;
    }

    logout() {
        this.token = null;
    }

    private prepareConfig(userConfig: LorythRequestConfig): LorythRequestConfig {
        const config: LorythRequestConfig = {};

        config.headers = {
            ...userConfig.headers,
        };

        if (this.token) {
            config.headers["Authorization"] = `Bearer ${this.token}`;
        }

        return config;
    }

    get<T = any>(endpoint: string, config: LorythRequestConfig = {}) {
        config = this.prepareConfig(config);

        return axios.get<T>(
            this.url + endpoint,
            { headers: config.headers },
        )
    }

    post<T = any>(endpoint: string, data: any, config: LorythRequestConfig = {}) {
        config = this.prepareConfig(config);

        return axios.post<T>(
            this.url + endpoint,
            data,
            { headers: config.headers },
        )
    }

    put<T = {}>(endpoint: string, data: any, config: LorythRequestConfig = {}) {
        config = this.prepareConfig(config);

        return axios.put<T>(
            this.url + endpoint,
            data,
            { headers: config.headers },
        )
    }

    delete<T = void>(endpoint: string, config: LorythRequestConfig = {}) {
        config = this.prepareConfig(config);

        return axios.delete<T>(
            this.url + endpoint,
            { headers: config.headers },
        )

    }
}

interface LorythRequestConfig {
    headers?: Record<string, string>;
}

export interface PagedResult<T> {
    items: T[];
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
}


interface ReduxResourceConfig<Resource = {}, Criteria = {}> {
    fetch: (criteria: Criteria) => Promise<Resource>;
    onFetchResult?: (resource: Resource) => AnyAction;
}


export const createReduxResource = <Resource = {}, Criteria = {}>(
    type: string,
    config: ReduxResourceConfig<Resource, Criteria>,
) => {
    const ActionType = {
        FetchRequest: `${type}/fetch/requested`,
        FetchResult: `${type}/fetch/loaded`,
        FetchError: `${type}/fetch/failed`,
    };

    const match = (action: AnyAction) =>
        action.type === ActionType.FetchRequest
        || action.type === ActionType.FetchResult
        || action.type === ActionType.FetchError;

    const fetchRequest = (queryId: string, criteria: Criteria) => ({
        type: ActionType.FetchRequest,
        payload: {
            queryId,
            criteria,
        },
    });

    const fetchResult = (queryId: string, resource: Resource) => ({
        type: ActionType.FetchResult,
        payload: {
            queryId,
            resource,
        },
    });

    const fetchError = (queryId: string, error: ResourceError) => ({
        type: ActionType.FetchError,
        payload: {
            queryId,
            error,
        },
    });

    const fetch = (queryId: string, criteria: Criteria): ThunkAction<void, any, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(fetchRequest(queryId, criteria));
            try {
                const resource = await config.fetch(criteria);
                dispatch(fetchResult(queryId, resource));
                if (config.onFetchResult) {
                    dispatch(config.onFetchResult(resource));
                }
            } catch (e) {
                // TODO(souperk): capture error message
                dispatch(fetchError(queryId, "failed to fetch resource"));
            }
        };

    const reducer = (state: ReduxQueryIndex<Resource> = {}, action: AnyAction): ReduxQueryIndex<Resource> => {
        switch (action.type) {
            case ActionType.FetchRequest:
                return {
                    ...state,
                    [action.payload.queryId]: {
                        status: ResourceRequested,
                    },
                }

            case ActionType.FetchResult:
                return {
                    ...state,
                    [action.payload.queryId]: {
                        status: ResourceReady,
                        resource: action.payload.resource as Resource,
                    },
                }

            case ActionType.FetchError:
                return {
                    ...state,
                    [action.payload.queryId]: {
                        status: ResourceFailed,
                        error: action.payload as ResourceError,
                    },
                }

            default:
                return state;
        }
    }

    return {
        match,
        reducer,
        fetch,
    }
}

export const api = new LorythAPI("https://loryth.com");
// export const api = new LorythAPI("http://localhost:8080");

