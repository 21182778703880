import { deserializer, serializer } from "commons/models";
import { createIndex } from "commons/redux";
import { Mood, MoodieDailyMood } from "models";

export interface MoodieDailyMoodState {
    id: string;
    timestamp: string;
    mood?: Mood;
}

const serialize = serializer<MoodieDailyMood, MoodieDailyMoodState>((record) => ({
    ...record,
    timestamp: record.timestamp.toISOString(),
}))

const deserialize = deserializer<MoodieDailyMood, MoodieDailyMoodState>(record => ({
    ...record,
    timestamp: new Date(record.timestamp),
}))

const index = createIndex(
    "MOODIE_DAILY_MOOD",
    serialize,
)

export const DailyMoodRedux = {
    ...index,
    serialize,
    deserialize,
}