import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import {
    ResourceEmpty,
    ResourceFailed,
    ResourceReady,
    ResourceRequested,
    ResourceState,
    ResourceStatus
} from "commons/models";
import { Account } from "models/Account";
import { useSelector } from "react-redux";
import {
    api
} from "state/api";

interface LoadInitAction {
    type: "AUTH_PROFILE_LOAD_INIT";
}

interface LoadSuccessAction {
    type: "AUTH_PROFILE_LOAD_SUCCESS";
    payload: Account;
}

interface LoadErrorAction {
    type: "AUTH_PROFILE_LOAD_ERROR";
    payload: Error | string;
}

export type ProfileLoadAction = LoadInitAction | LoadSuccessAction | LoadErrorAction;

export const fetchProfile = (): ThunkAction<void, any, unknown, AnyAction> =>
    async (dispatch, getState) => {
        const { auth } = getState();
        if (!auth.token) {
            return;
        }

        dispatch(init());
        const response = await api.get("/api/account/profile");

        if (response.status === 200) {
            dispatch(success(response.data));
        } else {
            dispatch(error(response.data));
        }
    }

const init = (): LoadInitAction => {
    return {
        type: "AUTH_PROFILE_LOAD_INIT",
    }
}

const success = (profile: Account): LoadSuccessAction => {
    return {
        type: "AUTH_PROFILE_LOAD_SUCCESS",
        payload: profile,
    }
}

const error = (error: Error | string): LoadErrorAction => {
    return {
        type: "AUTH_PROFILE_LOAD_ERROR",
        payload: error,
    }
}


export type ProfileAction = ProfileLoadAction;

export type ProfileState = ResourceState<Account>;

const initialState: ProfileState = {
    status: ResourceEmpty,
};

export const ProfileReducer = (state: ProfileState = initialState, action: ProfileAction): ProfileState => {
    switch (action.type) {
        case "AUTH_PROFILE_LOAD_INIT":
            return {
                status: ResourceRequested,
            }

        case "AUTH_PROFILE_LOAD_SUCCESS":
            return {
                status: ResourceReady,
                resource: action.payload,
            }

        case "AUTH_PROFILE_LOAD_ERROR":
            return {
                status: ResourceFailed,
                error: action.payload,
            }

        default:
            return state;
    }
}

export const useProfile = (): Account | null => {
    return useSelector((state: any) => state.auth.profile.resource);
}

export const useProfileStatus = (): ResourceStatus => {
    return useSelector((state: any) => state.auth.profile.status);
}