export interface BaseModel {
    id: string;
}

export type New<Model extends BaseModel> = Omit<Model, "id">;

export const isPersisted = <Model extends BaseModel>(model: Model | New<Model>): model is Model => "id" in model;
export const isNew = <Model extends BaseModel>(model: Model | New<Model>): model is New<Model> => !isPersisted(model);

export const id = (model: BaseModel) => model.id;
