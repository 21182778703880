import { api, PagedResult } from '../api'
import { createQuery } from '../query'

import { New } from 'commons/models'
import { isNew } from 'commons/models/BaseModel'

import { MoodieDiaryRecord } from "models";

import { DiaryRecordRedux, MoodieDiaryRecordState } from "./moodie-diary-record.redux";


const fetchAll = async (_: undefined) => {
    const response = await api.post<PagedResult<MoodieDiaryRecordState>>("/api/mood-tracker/thought/query", {});

    if (response.status === 200) {
        return response.data.items;
    }

    throw new Error("failed to fetch resource");
};

const FetchAll = createQuery<MoodieDiaryRecordState[], undefined>(fetchAll, {
    result: records => ((dispatch: any) => {
        for (const record of records) {
            dispatch(DiaryRecordRedux.actions.add(
                DiaryRecordRedux.deserialize(record)
            ));
        }
    }) as any,
})
const create = async (record: New<MoodieDiaryRecord>) => {
    const response = await api.post<MoodieDiaryRecordState>(
        `/api/mood-tracker/thought`,
        DiaryRecordRedux.serialize(record),
    );

    if (response.status === 200) {
        return response.data;
    }

    throw new Error("failed to insert resource");
}

const Create = createQuery<MoodieDiaryRecordState, MoodieDiaryRecord>(create, {
    result: record => DiaryRecordRedux.actions.add(
        DiaryRecordRedux.deserialize(record)
    ),
})


const update = async (record: MoodieDiaryRecord) => {
    const response = await api.put<MoodieDiaryRecordState>(
        `/api/mood-tracker/thought/${record.id}`,
        DiaryRecordRedux.serialize(record),
    );

    if (response.status === 200) {
        return response.data;
    }

    throw new Error("failed to update resource");
}

const Update = createQuery<MoodieDiaryRecordState, MoodieDiaryRecord>(update, {
    result: record => DiaryRecordRedux.actions.add(
        DiaryRecordRedux.deserialize(record)
    ),
})

const createOrUpdate = async (record: MoodieDiaryRecord | New<MoodieDiaryRecord>) => {
    if (isNew(record)) {
        return create(record);
    } else {
        return update(record);
    }
}

const CreateOrUpdate = createQuery<MoodieDiaryRecordState, MoodieDiaryRecord | New<MoodieDiaryRecord>>(createOrUpdate, {
    result: record => DiaryRecordRedux.actions.add(
        DiaryRecordRedux.deserialize(record)
    ),
});

const delete_ = async (id: string) => {
    const response = await api.delete(`/api/mood-tracker/thought/${id}`);

    if (response.status === 204) {
        return id;
    }

    throw new Error("failed to delete resource");
}

const Delete = createQuery<string, string>(delete_, {
    result: id => DiaryRecordRedux.actions.remove(id),
})

export const RecordQueries = {
    FetchAll,
    Create,
    Update,
    CreateOrUpdate,
    Delete,
}