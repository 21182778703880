import React from "react";

import { Box, styled } from "@mui/material";


const PositionGrid = ({
    columns,
    rows,
    maxWidth = "1fr",
    maxHeight = "1fr",
    children,
}: PositionGridProps) => {
    if (typeof maxWidth === "number") {
        maxWidth = maxWidth + "px";
    }
    if (typeof maxHeight === "number") {
        maxHeight = maxHeight + "px";
    }

    return (
        <PositionGridRoot sx={{
            display: "grid",
            gridTemplateColumns: `auto repeat(${columns}, ${maxWidth}) 1fr`,
            gridTemplateRows: `auto repeat(${rows}, ${maxHeight})`,
        }}>
            {children}
        </PositionGridRoot>
    )
}

const PositionGridRoot = styled(Box)((_) => ({
    overflow: "auto",
}));

export interface PositionGridProps {
    columns: number;
    rows: number;
    maxWidth?: number | string;
    maxHeight?: number | string;
    children?: React.ReactNode;
}

export default PositionGrid;