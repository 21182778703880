import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { ResourceEmpty } from "commons/models";
import { useSelector } from "react-redux";
import {
    api
} from "state/api";
import { ProfileAction, ProfileState, ProfileReducer } from "state/auth/profile.redux";

interface SetStateInitAction {
    type: "AUTH_INIT";
    payload: {
        token: string | null;
    }
}

interface SetStateCompletedAction {
    type: "AUTH_COMPLETED";
    payload: {
        token: string;
    }
}

export type AuthStateAction = SetStateInitAction | SetStateCompletedAction;

export const init = (token: string | null): SetStateInitAction => {
    return {
        type: "AUTH_INIT",
        payload: { token },
    }
}

export const completed = (token: string): SetStateCompletedAction => {
    return {
        type: "AUTH_COMPLETED",
        payload: { token },
    }
}

interface LoginCredentials {
    email: string;
    password: string;
}

export const login = (credentials: LoginCredentials, redirectOnLogin?: () => void): ThunkAction<void, any, unknown, AnyAction> =>
    async dispatch => {
        const response = await api.post("/api/account/login", {
            email: credentials.email,
            password: credentials.password,
        });

        if (response.status === 200) {
            // login was successful
            dispatch(completed(response.data.token));
            api.authenticate(response.data.token);
            localStorage.setItem("token", response.data.token);

            if (redirectOnLogin) {
                redirectOnLogin();
            }
        }
    }

interface SignUpCredentials {
    username: string;
    email: string;
    password: string;
}

export const signUp = (credentials: SignUpCredentials, redirectOnLogin?: () => void): ThunkAction<void, any, unknown, AnyAction> =>
    async dispatch => {
        const response = await api.post("/api/account/register", {
            username: credentials.username,
            email: credentials.email,
            password: credentials.password,
        });

        if (response.status === 200) {
            // login was successful
            dispatch(completed(response.data.token));
            api.authenticate(response.data.token);
            localStorage.setItem("token", response.data.token);

            if (redirectOnLogin) {
                redirectOnLogin();
            }
        }
    }

export const loadToken = (): ThunkAction<void, any, unknown, AnyAction> =>
    dispatch => {
        const token = localStorage.getItem("token");
        if (token) {
            api.authenticate(token);
        }
        dispatch(init(token));
    }

export type AuthAction = AuthStateAction | ProfileAction;

export interface AuthState {
    initialized: boolean;
    token?: string | null;
    profile: ProfileState;
}


const getInitialState = (): AuthState => {
    return {
        initialized: false,
        profile: {
            status: ResourceEmpty,
        }
    };
}

export const AuthReducer = (state: AuthState = getInitialState(), action: AuthAction): AuthState => {
    switch (action.type) {
        case "AUTH_INIT":
            return {
                ...state,
                initialized: true,
                token: action.payload.token,
            };

        case "AUTH_COMPLETED":
            return {
                ...state,
                token: action.payload.token
            };

        case "AUTH_PROFILE_LOAD_INIT":
        case "AUTH_PROFILE_LOAD_SUCCESS":
        case "AUTH_PROFILE_LOAD_ERROR":
            return {
                ...state,
                profile: ProfileReducer(state.profile, action),
            }

        default:
            return state;
    }
}

export const useAuthStatus = (): "init" | "authenticated" | "anonymous" => {
    return useSelector((state: any) => {
        if (!state.auth.initialized) {
            return "init";
        }
        if (state.auth.token) {
            return "authenticated";
        }
        return "anonymous";
    });
}

