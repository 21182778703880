import React, { useEffect } from "react";
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ResourceEmpty } from "commons/models";

import { AppTemplate, AppTheme } from "components/template";
import LoadingSpinner from "components/template/LoadingSpinner";

import HomePage from "pages/HomePage";
import LogInPage from "pages/LogInPage";
import NotesHomePage from "pages/notes/NoteBoardPage";
import SignUpPage from "pages/SignUpPage";
import CalendarPage from "pages/CalendarPage";
import MoodieMainPage from "pages/moodie/MoodieMainPage";
import NotePage from "pages/notes/NotePage";

import { store } from "state";
import { useAuthStatus, useProfileStatus, loadToken, fetchProfile } from "state/auth";

const App = () => {
    return (
        <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AppStateLoader/>
                <AppTheme>
                    <AppRoutes/>
                </AppTheme>
            </LocalizationProvider>
        </ReduxProvider>
    );
};

const AppStateLoader = () => {
    const dispatch = useDispatch();

    // load loryth api token once when application is
    // initializing.
    useEffect(() => {
        dispatch(loadToken() as any);
    }, [dispatch])

    // load profile once
    // TODO(souperk): fix issue with profile being loaded twice
    const profileStatus = useProfileStatus();
    useEffect(
        () => {
            if (profileStatus === ResourceEmpty) {
                dispatch(fetchProfile() as any);
            }
        },
        [profileStatus, dispatch],
    )


    // return an empty React.Fragment because this component is not about rendering
    return <></>
}

const AppRoutes = () => {
    const status = useAuthStatus();
    return (
        <Routes>
            {routes(status)}
        </Routes>
    );
}

const routes = (status: "init" | "authenticated" | "anonymous") => {
    switch (status) {
        case "init":
            // use a loading spinner while the application is loading authentication
            // status
            return (
                <Route path="*" element={<LoadingSpinner/>}/>
            );

        case "authenticated":
            return (
                <Route path="" element={<AppTemplate/>}>
                    <Route index element={<HomePage/>}/>
                    <Route path="/calendar" element={<CalendarPage/>}/>
                    <Route path="/notes/board" element={<NotesHomePage/>}/>
                    <Route path="/notes/note/:noteId" element={<NotePage/>}/>
                    <Route path="/notes/*" element={<Navigate to="/notes/board"/>}/>
                    <Route path="/moodie" element={<MoodieMainPage/>}/>
                    <Route path="/moodie/*" element={<Navigate to="/moodie"/>}/>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Route>
            );

        case "anonymous":
        default:
            return (
                <>
                    <Route path="/login" element={<LogInPage/>}/>
                    <Route path="/sign-up" element={<SignUpPage/>}/>
                    <Route path="*" element={<Navigate to="/login"/>}/>
                </>
            );
    }
}

export default App;