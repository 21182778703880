import { configureStore } from "@reduxjs/toolkit";

import { AuthReducer } from "state/auth/auth.redux";
import { MenuReducer } from "state/menu.redux";
import { MoodieReducer } from "state/moodie/moodie.redux";
import { NotesReducer } from "state/notes/notes.redux";
import { ThemeReducer } from "state/theme.redux";
import { QueryReducer } from "./query";


export const store = configureStore({
    reducer: {
        menu: MenuReducer,
        auth: AuthReducer,
        notes: NotesReducer,
        moodie: MoodieReducer,
        theme: ThemeReducer,
        queries: QueryReducer,
    },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
