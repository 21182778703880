import { Box, Paper, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { monthDate, monthName } from "./utils";
import { CalendarMonthIcon } from "components-lib/icons";

const CalendarTodayWidget = ({
    date
}: Props) => {
    const navigate = useNavigate();
    const day = monthDate(date);
    const month = monthName(date);

    const handleClick = () => {
        navigate("/calendar");
    };

    return (
        <CalendarTodayWrapper onClick={handleClick}>
            <CalendarMonthIcon sx={{ fontSize: "64px" }}/>
            <CalendarTodayDate>
                <Typography variant="h2" component="span">{day}</Typography>
                <Typography variant="h4" component="span">{month}</Typography>
            </CalendarTodayDate>
        </CalendarTodayWrapper>
    );
}

const CalendarTodayWrapper = styled(Paper)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    padding: theme.spacing(1, 4),
    borderRadius: "12px",

    backgroundColor: theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.background.default,
    color: theme.palette.mode === "light" ? theme.palette.primary.contrastText : theme.palette.text.primary,

    "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.action.hover,
    }
}));

const CalendarTodayDate = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
}));


interface Props {
    date: Date;
}

export default CalendarTodayWidget;