export function utcDate(): Date;
export function utcDate(year: number, month?: number, date?: number): Date;
export function utcDate(date: Date): Date;
export function utcDate(yearOrDate?: number | Date, month: number = 0, date: number = 1): Date {
    if (yearOrDate === undefined) {
        const today = new Date();
        return new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    }
    if (yearOrDate instanceof Date) {
        month = yearOrDate.getMonth();
        date = yearOrDate.getDate();
        yearOrDate = yearOrDate.getFullYear();
    }
    return new Date(Date.UTC(yearOrDate, month, date));
}

export function utcYear(): Date;
export function utcYear(year: number): Date;
export function utcYear(date: Date): Date;
export function utcYear(yearOrDate?: Date | number) {
    if (yearOrDate === undefined) {
        yearOrDate = utcDate(new Date());
    }

    if (yearOrDate instanceof Date) {
        yearOrDate = yearOrDate.getFullYear();
    }
    return utcDate(yearOrDate);
}

export function utcMonth(): Date;
export function utcMonth(year: number, month: number): Date;
export function utcMonth(date: Date): Date;
export function utcMonth(yearOrDate?: Date | number, month: number = 0) {
    if (yearOrDate === undefined) {
        yearOrDate = utcYear();
    }

    if (yearOrDate instanceof Date) {
        month = yearOrDate.getMonth();
        yearOrDate = yearOrDate.getFullYear();
    }
    return utcDate(yearOrDate, month);
}

export const utcToday = () => utcDate(new Date());

export const dayOfYear = (date: Date) => {
    date = utcDate(date);
    const year = utcDate(date.getFullYear());
    return Math.floor((date.getTime() - year.getTime()) / (24 * 60 * 60 * 1000));
}
export const dayOfWeek = (date: Date) => {
    date = utcDate(date);
    return date.getDay();
}
export const weekOfYear = (date: Date) => {
    date = utcDate(date);
    const year = utcDate(date.getFullYear());
    const days = dayOfYear(date);
    return Math.ceil((year.getDay() + days + 1) / 7);
}

export const utcAddDays = (date: Date, days: number) => {
    date = utcDate(date);
    return utcDate(date.getFullYear(), date.getMonth(), date.getDate() + days);
}
export const utcNextDay = (date: Date) => utcAddDays(date, 1);
export const utcPreviousDay = (date: Date) => utcAddDays(date, -1);

export const utcAddMonths = (date: Date, months: number) => {
    date = utcDate(date);
    return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
}
export const utcNextMonth = (date: Date) => utcAddMonths(date, 1);
export const utcPreviousMonth = (date: Date) => utcAddMonths(date, -1);

export const utcAddYears = (date: Date, years: number) => {
    date = utcDate(date);
    return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
}

export const utcNextYear = (date: Date) => utcAddYears(date, 1);
export const utcPreviousYear = (date: Date) => utcAddYears(date, -1);

export const utcRange = (start: Date, end: Date) => {
    start = utcDate(start);
    end = utcDate(end);
    const dates: Date[] = [];
    for (let current = start; current <= end; current = utcNextDay(current)) {
        dates.push(current)
    }
    return dates;
}

export const utcDateEquals = (a: Date, b: Date) => {
    a = utcDate(a);
    b = utcDate(b);
    return a.getFullYear() === b.getFullYear()
        && a.getMonth() === b.getMonth()
        && a.getDate() === b.getDate()
        ;
}