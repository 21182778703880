import { New } from "commons";

import { MoodieEmotion } from "./MoodieEmotion";


export interface MoodieDiaryRecord {
    id: string;
    timestamp: Date;
    content: string;
    emotions: MoodieEmotion[];
}

export const newDiaryRecord = (): New<MoodieDiaryRecord> => ({
    content: "",
    timestamp: new Date(),
    emotions: [],
});