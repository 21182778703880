import React, { MouseEventHandler, useState } from "react";

import { Drawer, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

import { useDevice } from "commons/media";
import { DeleteIcon, EditIcon, MoreVertIcon } from "components-lib/icons";


const OptionsButton = ({
    onEdit,
    onDelete,
}: OptionsButtonParams) => {
    const { mobile } = useDevice();
    const [anchor, setAnchor] = useState<HTMLElement | null>();
    const open = Boolean(anchor);

    const close = () => {
        setAnchor(null);
    }

    const handleClose = () => {
        close();
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(e.currentTarget);
        e.preventDefault();
    }

    const wrapOnClick = (onClick: MouseEventHandler) => (e: React.MouseEvent<HTMLDivElement>) => {
        close();
        onClick(e);
    }

    const menuItems = [];
    if (onEdit) {
        menuItems.push(<EditMenuItem key="menu-edit" onClick={wrapOnClick(onEdit)}/>);
    }
    if (onDelete) {
        menuItems.push(<DeleteMenuItem key="menu-delete" onClick={wrapOnClick(onDelete)}/>)
    }

    return (
        <>
            <IconButton
                aria-controls={open ? "options" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            {mobile ?
                <Drawer anchor="bottom" open={open} onClose={handleClose}>
                    {menuItems}
                </Drawer>
                :
                <Menu anchorEl={anchor} open={open} onClose={handleClose}>
                    {menuItems}
                </Menu>
            }
        </>
    )
};

interface OptionsButtonParams {
    onEdit?: MouseEventHandler;
    onDelete?: MouseEventHandler;
}

export const EditMenuItem = ({ onClick }: MenuItemProps) => {
    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon>
                <EditIcon color="primary"/>
            </ListItemIcon>
            <ListItemText>
                Edit
            </ListItemText>
        </MenuItem>
    )
}

export const DeleteMenuItem = ({ onClick }: MenuItemProps) => {
    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon>
                <DeleteIcon color="error"/>
            </ListItemIcon>
            <ListItemText>
                Delete
            </ListItemText>
        </MenuItem>
    )
}

interface MenuItemProps {
    onClick?: MouseEventHandler;
}


export default OptionsButton;