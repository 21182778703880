import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

export const Breakpoints: Breakpoint[] = ["xs", "sm", "md", "lg", "xl"];

export type BreakpointValues<T> = Record<Breakpoint, T>;

// @ts-ignore
export const firstBreakpoint = <T>(values: BreakpointValues<T>): T => {
    for (const breakpoint of Breakpoints) {
        if (breakpoint in values) {
            return values[breakpoint];
        }
    }
}

export function useBreakpoints<T>(...values: T[]): T;
export function useBreakpoints<T>(values: BreakpointValues<T>): T;

export function useBreakpoints<T>(
    valueOrObject: T | BreakpointValues<T>,
    ...values: T[]
) {
    if (typeof valueOrObject === "object") {
        const valuesObject = valueOrObject as BreakpointValues<T>;
        values = [];
        let previousValue = firstBreakpoint(valuesObject);
        for (const breakpoint of Breakpoints) {
            if (breakpoint in valuesObject) {
                previousValue = valuesObject[breakpoint];
            }
            values.push(previousValue);
        }
    } else {
        values = [valueOrObject, ...values]
    }

    const theme = useTheme();
    const matches = [];
    for (const breakpoint of Breakpoints) {
        // Disable lint error, the number of iterations is static.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const bMatch = useMediaQuery(theme.breakpoints.only(breakpoint as Breakpoint));
        matches.push(bMatch);
    }

    for (let i = 0; i < values.length; i++) {
        if (matches[i]) {
            return values[i];
        }
    }
    return values[values.length - 1];
}

export type DeviceType = "mobile" | "desktop";
export const useDevice = () => {
    const deviceType = useBreakpoints<DeviceType>("mobile", "mobile", "desktop");
    return {
        deviceType,
        mobile: deviceType === "mobile",
        desktop: deviceType === "desktop",
    }
}

export const hasTouch = () => {
    return "ontouchstart" in document.documentElement
        || navigator.maxTouchPoints > 0
        || ("msMaxTouchPoints" in navigator && (navigator["msMaxTouchPoints"] as number) > 0);
}