import { textSearch } from "commons/utils";
import { useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";

import { createResource, isReady, ResourceState } from "commons/models";
import { New } from "commons/models/BaseModel";
import { ReduxIndex } from "commons/redux";
import { newNote, Note } from "models";
import { useQuery } from 'state/query'

import {
    deserialize,
    matchNoteAction,
    NoteAction,
    NoteReducer, NoteState
} from "./note.redux";
import { Query } from './notes.queries'

export type NotesActions = NoteAction;

export const matchNotesAction = (action: AnyAction): action is NotesActions => matchNoteAction(action);

export interface NotesPluginState {
    notes: ReduxIndex<NoteState>;
}

const initialState: NotesPluginState = {
    notes: {},
}

export const NotesReducer = (state: NotesPluginState = initialState, action: AnyAction): NotesPluginState => {
    if (!matchNotesAction(action)) {
        return state;
    }

    switch (action.type) {
        case "NOTE_ADD":
        case "NOTE_UPDATE":
        case "NOTE_DELETE":
            return {
                ...state,
                notes: NoteReducer(state.notes, action as NoteAction),
            }

        default:
            return state;
    }
}

const usePluginSelector = <T>(selector: (state: NotesPluginState) => T): T =>
    useSelector((state: any) => selector(state.notes));

interface NotesFilter {
    search?: string;
    createdAt?: Date;
    updatedAt?: Date;
    from?: Date | null;
    to?: Date | null;
    orderBy?: "createdAt" | "updatedAt" | "title";
}

export const useNotes = (id?: string, filter: NotesFilter = {}): ResourceState<Note[]> => {
    const query = useQuery(id || "notes-notes-global", Query.FetchNotes, undefined);
    return usePluginSelector(state => {
        if (!isReady(query)) {
            return query;
        }

        return createResource<Note[]>(
            Object.values<NoteState>(state.notes)
                .map(x => deserialize(x))
                .filter(x => {
                    let result = true;
                    if (filter.search) {
                        result &&=
                            textSearch(x.title, filter.search)
                            || textSearch(x.content, filter.search);
                    }
                    if (filter.createdAt) {
                        result &&= x.createdAt !== undefined
                            && x.createdAt.getFullYear() === filter.createdAt.getFullYear()
                            && x.createdAt.getMonth() === filter.createdAt.getMonth()
                            && x.createdAt.getDate() === filter.createdAt.getDate();
                    }
                    if (filter.updatedAt) {
                        result &&= x.updatedAt !== undefined
                            && x.updatedAt.getFullYear() === filter.updatedAt.getFullYear()
                            && x.updatedAt.getMonth() === filter.updatedAt.getMonth()
                            && x.updatedAt.getDate() === filter.updatedAt.getDate();
                    }
                    if (filter.from) {
                        result &&= x.createdAt !== undefined
                            && new Date(x.createdAt.getFullYear(), x.createdAt.getMonth(), x.createdAt.getDate())
                            >= new Date(filter.from.getFullYear(), filter.from.getMonth(), filter.from.getDate())
                    }
                    if (filter.to) {
                        result &&= x.createdAt !== undefined
                            && new Date(x.createdAt.getFullYear(), x.createdAt.getMonth(), x.createdAt.getDate())
                            <= new Date(filter.to.getFullYear(), filter.to.getMonth(), filter.to.getDate())
                    }
                    return result;
                }).sort((a, b) => {
                switch (filter.orderBy) {
                    case "title":
                        return b.title.localeCompare(a.title);

                    case "createdAt":
                        return b.createdAt.getTime() - a.createdAt.getTime();

                    case "updatedAt":
                    default:
                        return b.updatedAt.getTime() - a.updatedAt.getTime();

                }
            })
        )
    });
};


export function useNote(id: string): ResourceState<Note> {
    const query = useQuery(id, Query.FetchNote, { id });
    return usePluginSelector(state => {
        if (!isReady(query)) {
            return query;
        }
        return createResource(deserialize(state.notes[id]));
    });
}

export function useNoteOrCreate(id?: string): Note | New<Note> {
    return usePluginSelector(state => {
        if (id === undefined) {
            return newNote();
        }

        return deserialize(state.notes[id]);
    })
}

