// DOCS(souperk)
export function debounce(callback: () => void, delay: number = 200): () => void {
    let timer: number | undefined;
    return () => {
        if (timer) {
            clearTimeout(timer)
        }

        timer = setTimeout(_ => {
            timer = undefined;
            callback();
        }, delay);
    };
}