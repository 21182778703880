import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { Dialog } from "components-lib/layout";


const DeleteDialog = ({
    title,
    question,
    open,
    onClose,
    onDelete,
}: DeleteDialogProps) => {
    const handleDelete = () => {
        onDelete();
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} fullScreen={false}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {question}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}> Cancel </Button>
                <Button onClick={handleDelete}
                        color="error">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
};

interface DeleteDialogProps {
    title: string;
    question: string;
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

export default DeleteDialog;