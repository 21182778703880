import { Container, Grid, Link } from '@mui/material';
import { Header } from 'components-lib/layout/Header';
import React from 'react';
import LoginForm from 'components/forms/LoginForm';

const LoginPage = (): JSX.Element => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header
            center={<h4>Login</h4>}
            right={<Link href="/sign-up">Sign Up</Link>}
          />
        </Grid>
        <Grid item xs={12}>
          <LoginForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;
