import { New, isNew } from 'commons/models'
import { Note } from 'models'
import { api, PagedResult } from 'state/api'
import { createQuery } from 'state/query'

import {
    deleteNote as deleteNoteAction,
    deserialize,
    insertNote as insertNoteAction,
    NoteState,
    serialize,
    updateNote as updateNoteAction,
} from './note.redux'


export const fetchNotes = async (_: undefined) => {
    const response = await api.post<PagedResult<NoteState>>("/api/notes/note/query", {});

    if (response.status === 200) {
        return response.data.items;
    }

    throw new Error("failed to fetch resource");
};

const FetchNotes = createQuery<NoteState[], undefined>(fetchNotes, {
    result: result => ((dispatch: any) => {
        for (const note of result) {
            dispatch(insertNoteAction(deserialize(note)));
        }
    }) as any,
})

export const fetchNote = async ({ id }: { id: string }) => {
    const response = await api.get<NoteState>(`/api/notes/note/${id}`);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error("failed to fetch resource");
}

const FetchNote = createQuery<NoteState, { id: string }>(fetchNote, {
    result: result => insertNoteAction(deserialize(result)),
});

export const createNote = async (note: New<Note>) => {
    const response = await api.post<NoteState>(`/api/notes/note`, serialize(note));

    if (response.status === 200) {
        return response.data;
    }

    throw new Error("failed to insert resource");
}

const CreateNote = createQuery<NoteState, Note>(createNote, {
    result: result => insertNoteAction(deserialize(result)),
})


export const updateNote = async (note: Note) => {
    const response = await api.put<NoteState>(`/api/notes/note/${note.id}`, serialize(note));

    if (response.status === 200) {
        return response.data;
    }

    throw new Error("failed to update resource");
}

const UpdateNote = createQuery<NoteState, Note>(updateNote, {
    result: result => updateNoteAction(deserialize(result))
})

export const createOrUpdateNote = async (note: Note | New<Note>) => {
    if (isNew(note)) {
        return createNote(note);
    } else {
        return updateNote(note);
    }

}

const CreateOrUpdateNote = createQuery<NoteState, Note | New<Note>>(createOrUpdateNote, {
    result: result => updateNoteAction(deserialize(result)),
});

export const deleteNote = async (id: string) => {
    const response = await api.delete(`/api/notes/note/${id}`);

    if (response.status === 204) {
        return id;
    }

    throw new Error("failed to delete resource");
}

const DeleteNote = createQuery<string, string>(deleteNote, {
    result: result => deleteNoteAction(result),
})

export const Query = {
    FetchNotes,
    FetchNote,
    CreateNote,
    UpdateNote,
    CreateOrUpdateNote,
    DeleteNote,
}