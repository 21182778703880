import { useTheme } from "@mui/material";

import { isReady, resource } from "commons/models";
import { CalendarItem } from "models";
import { useNotes } from "state/notes";

export const useCalendar = (date: Date): { items: CalendarItem[] } => {
    const query = useNotes("calendar", { updatedAt: date, orderBy: "updatedAt" });
    const theme = useTheme();

    return {
        items: !isReady(query) ? [] : resource(query).map(note => ({
            timestamp: note.updatedAt,
            title: note.title,
            color: theme.palette.primary.main,
            link: `/notes/note/${note.id}`,
        }))
    }
}