import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import { useDevice } from "commons/media";
import AppHeader from "components/template/AppHeader";
import AppMenu from "components/template/AppMenu";


const AppTemplate = () => {
    const { desktop } = useDevice();

    return (
        <Box sx={{
            display: "grid",
            gridTemplateRows: "auto 1fr",
            maxHeight: "100vh",
        }}>
            <AppHeader/>
            <AppMenu/>
            <Box sx={{
                overflow: "auto",
                marginLeft: desktop ? "360px" : 0,
            }}>
                <Outlet/>
            </Box>
        </Box>
    )
}

export default AppTemplate;