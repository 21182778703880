import Grid from "@mui/material/Grid";
import React from "react";

export const Header = ({
    left,
    center,
    right
}: HeaderProps): JSX.Element => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}> {left} </Grid>
            <Grid item xs={6}> {center} </Grid>
            <Grid item xs={3} justifyContent="center"> {right} </Grid>
        </Grid>
    );
}

interface HeaderProps {
    left?: React.ReactNode;
    center?: React.ReactNode;
    right?: React.ReactNode;
}