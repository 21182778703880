import { List, ListItem } from "@mui/material";

import { useCalendar } from "state/calendar";

import CalendarListingRow from "./CalendarListingRow";

const CalendarListing = ({ date }: CalendarListingProps) => {
    const { items } = useCalendar(date);

    return (
        <List>
            {items.map((item, index) => (
                <ListItem key={index} sx={{ display: "list-item", padding: 0 }}>
                    <CalendarListingRow item={item} />
                </ListItem>
            ))}
        </List>
    );
}

export interface CalendarListingProps {
    date: Date;
}

export default CalendarListing;