import { useDevice } from "commons/media";
import React from "react";

import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { useCommandTrigger } from "commons";
import { Dialog } from "components-lib/layout/index";


const EditDialog = ({
    title,
    open,
    onClose: handleClose,
    children,
}: Props) => {
    const { mobile } = useDevice();
    const save = useCommandTrigger("save");
    const reset = useCommandTrigger("reset");

    const handleSave = () => {
        save();
        handleClose();
    }

    const handleCancel = () => {
        reset();
        handleClose();
    }

    const paperProps = mobile ? {
        sx: {
            minHeight: "fill-available",
            height: "unset",
        },
    } : {
        sx: { height: "80%" },
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            PaperProps={paperProps}>
            <DialogTitle> {title} </DialogTitle>
            <DialogContent sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0,
                flex: "1 1 0",
            }}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button color="primary" onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>)
}

interface Props {
    title: string;
    open: boolean;
    onClose: () => void;
    children?: React.ReactNode;
}

export default EditDialog;