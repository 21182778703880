import { useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";

import {
    createResource,
    isReady,
    New,
    ReduxIndex,
    matchDate,
    textSearch
} from "commons";
import { MoodieDailyMood, MoodieDiaryRecord, newDailyMood, newDiaryRecord } from "models";
import { useQuery } from "state/query";

import { DailyMoodRedux } from "./moodie-daily-mood.redux";
import { DiaryQueries } from "./moodie-diary.query";
import { DiaryRecordRedux, MoodieDiaryRecordState } from "./moodie-diary-record.redux";
import {
    MoodieDailyMoodState
} from "./moodie-daily-mood.redux";


export interface MoodieState {
    daily: {
        mood: ReduxIndex<MoodieDailyMoodState>;
    };

    diary: {
        records: ReduxIndex<MoodieDiaryRecordState>;
    };
}

const initialState: MoodieState = {
    daily: {
        mood: {},
    },
    diary: {
        records: {},
    },
};

export const MoodieReducer = (state: MoodieState = initialState, action: AnyAction): MoodieState => {
    if (DailyMoodRedux.match(action)) {
        return {
            ...state,
            daily: {
                ...state.daily,
                mood: DailyMoodRedux.reducer(state.daily.mood, action),
            }
        };
    }

    if (DiaryRecordRedux.match(action)) {
        return {
            ...state,
            diary: {
                ...state.diary,
                records: DiaryRecordRedux.reducer(state.diary.records, action),
            }
        };
    }

    return state;
}


const usePluginState = <T>(selector: (state: MoodieState) => T): T =>
    useSelector((state: any) => selector(state.moodie));

interface DiaryRecordFilter {
    search?: string;
}

export const useDiaryRecords = (id?: string, filter: DiaryRecordFilter = {}) => {
    const query = useQuery(id || "moodie-diary-record-global", DiaryQueries.Record.FetchAll, undefined);
    return usePluginState(state => {
        if (!isReady(query)) {
            return query;
        }

        return createResource<MoodieDiaryRecord[]>(
            Object.values<MoodieDiaryRecordState>(state.diary.records)
                .map(x => DiaryRecordRedux.deserialize(x))
                .filter(record => {
                    let result = true;
                    if (filter.search) {
                        result &&= textSearch(record.content, filter.search);
                    }
                    return result;
                })
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
        )
    })
}

export const useDiaryRecordOrCreate = (id?: string): MoodieDiaryRecord | New<MoodieDiaryRecord> => {
    return usePluginState(state => {
        if (id === undefined) {
            return newDiaryRecord();
        }

        return DiaryRecordRedux.deserialize(state.diary.records[id]);
    })
}

export const useDailyMoodOrCreate = (date?: Date): MoodieDailyMood | New<MoodieDailyMood> => {
    return usePluginState(state => {
        if (date === undefined) {
            date = new Date();
        }

        const item = Object.values(state.daily.mood)
            .find(x => matchDate(date as Date, new Date(x.timestamp)));
        if (!item) {
            return newDailyMood();
        }

        return DailyMoodRedux.deserialize(item);
    })
}

export const useLogs = (): MoodieDailyMood[] => {
    return usePluginState(state => {
        return Object.values(state.daily.mood)
            .map(x => DailyMoodRedux.deserialize(x))
    })
}



