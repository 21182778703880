import React from 'react';
import SignUpForm from 'components/forms/SignUpForm';
import { Container, Grid, Link } from '@mui/material';
import { Header } from 'components-lib/layout/Header';

const SignUpPage = (): JSX.Element => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header
            center={<h4>Sign Up</h4>}
            right={<Link href="/login">Login</Link>}
          />
        </Grid>
        <Grid item xs={12}>
          <SignUpForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUpPage;
