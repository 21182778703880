import React from "react";

import { Box, styled } from "@mui/material";

import { renderHTML } from "commons/markdown";

const Markdown = React.forwardRef(({
    text,
    crop = false,
    ...other
}: MarkdownProps, ref) => {
    return (
        <MarkdownRoot ref={ref} {...other}>
            <div dangerouslySetInnerHTML={{ __html: renderHTML(text, { crop }) }}/>
        </MarkdownRoot>
    )
});

export interface MarkdownProps {
    text: string;
    crop?: boolean;
}

const MarkdownRoot = styled(Box, {
    name: "BlueMercuryMarkdown",
    slot: "Root",
    overridesResolver: (props, styles) => styles.root,
})(_ => ({}))

export default Markdown;