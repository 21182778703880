
export type CellPosition =
    [number, number]
    | { row: number; column: number }
    ;

export type HeaderPosition =
    number
    | [number, number]
    | { start: number; end: number; }
    ;

export const unwrapCellPosition = (position: CellPosition) => {
    if (position instanceof Array) {
        return position;
    }
    return [position.row, position.column];
}
export const unwrapHeaderPosition = (position: HeaderPosition) => {
    if (typeof position === "number") {
        return [position, position + 1];
    }
    if (position instanceof Array) {
        return position;
    }
    return [position.start, position.end];
}
