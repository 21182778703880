import { BaseModel, isPersisted, New } from "commons/models/BaseModel";

export interface Serializer<Model extends BaseModel, State extends BaseModel> {
    (note: Model): State;

    (note: New<Model>): New<State>;
}

export const serializer = <Model extends BaseModel, State extends BaseModel>(
    serializer: (model: New<Model>) => New<State>
): Serializer<Model, State> => (model => {
    const serialized = serializer(model);
    if (isPersisted(model)) {
        return {
            id: model.id,
            ...serialized,
        }
    }

    return serialized;
}) as Serializer<Model, State>
