import React from "react";
import { useDispatch } from "react-redux";

import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";

import { MenuIcon } from "components-lib/icons";
import ThemeSwitch from "components/ThemeSwitch";
import { MenuState } from "state";
import { useThemeMode, toggle as themeToggle } from "state/theme.redux";


const AppHeader = () => {
    const dispatch = useDispatch();
    const mode = useThemeMode()

    const onSidebarToggleClick = () => {
        dispatch(MenuState.toggle())
    }

    const handleThemeToggle = () => {
        dispatch(themeToggle());
    }

    return (
        <Box sx={{ flexGrow: 1, mb: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={onSidebarToggleClick}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Loryth
                    </Typography>
                    <ThemeSwitch checked={mode === "dark"} onChange={handleThemeToggle}/>
                </Toolbar>
            </AppBar>
        </Box>
    );
}


export default AppHeader;
