import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction, ThunkAction } from '@reduxjs/toolkit'

import { randomKey } from "commons/utils";
import {
    isEmpty,
    ReduxQueryIndex,
    ResourceError,
    ResourceFailed,
    ResourceReady,
    ResourceRequested,
    Resources,
    ResourceState
} from 'commons/models'


interface QueryRequest {
    type: "query/request";
    payload: {
        id: string;
    }
}

interface QueryResult<Resource = {}> {
    type: "query/result";
    payload: {
        id: string;
        resource: Resource;
    }
}

interface QueryError {
    type: "query/error";
    payload: {
        id: string;
        error: ResourceError;
    }
}

const request = (id: string): QueryRequest => ({
    type: 'query/request',
    payload: {
        id
    }
});

const result = <Resource = {}>(id: string, resource: Resource): QueryResult<Resource> => ({
    type: 'query/result',
    payload: {
        id,
        resource,
    }
})

const error = (id: string, error: ResourceError): QueryError => ({
    type: 'query/error',
    payload: {
        id,
        error,
    }
});

export type QueryAction = QueryRequest | QueryResult | QueryError;

const match = (action: AnyAction): action is QueryAction =>
    action.type === "query/request"
    || action.type === "query/result"
    || action.type === "query/error";

type QueriesState = ReduxQueryIndex<any>;

export const QueryReducer = (state: QueriesState = {}, action: AnyAction): QueriesState => {
    if (!match(action)) {
        return state;
    }

    switch (action.type) {
        case 'query/request':
            return {
                ...state,
                [action.payload.id]: {
                    status: ResourceRequested,
                },
            }

        case "query/result":
            return {
                ...state,
                [action.payload.id]: {
                    status: ResourceReady,
                    resource: action.payload.resource,
                },
            }

        case "query/error":
            return {
                ...state,
                [action.payload.id]: {
                    status: ResourceFailed,
                    error: action.payload.error,
                },
            }

        default:
            return state;
    }

}

interface ReduxQueryOptions<Resource = {}> {
    result?: (resource: Resource) => AnyAction;
}

export interface ReduxQuery<Criteria = {}> {
    action: (id: string, criteria: Criteria) => AnyAction;
    once: (criteria: Criteria) => AnyAction;
}

export const createQuery = <Resource = {}, Criteria = {}>(
    action: (criteria: Criteria) => Promise<Resource>,
    options: ReduxQueryOptions<Resource> = {},
): ReduxQuery<Criteria> => {
    const queryAction = (id: string, criteria: Criteria): ThunkAction<void, any, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(request(id));
            try {
                const resource = await action(criteria);
                dispatch(result(id, resource));
                if (options.result) {
                    dispatch(options.result(resource));
                }
            } catch (e) {
                // TODO(souperk): capture error message
                dispatch(error(id, "failed to fetch resource"));
            }
        };

    return {
        action: queryAction,
        once: (criteria: Criteria) => queryAction(randomKey(), criteria),
    } as unknown as ReduxQuery<Criteria>
}

export const useQuery = <Resource = {}, Criteria = {}>(
    id: string,
    query: ReduxQuery<Criteria>,
    criteria: Criteria,
): ResourceState<Resource> => {
    // TODO(souperk): cleanup state when query is no longer needed.
    const dispatch = useDispatch();
    const queryState = useSelector((state: any) => {
        if (id in state.queries) {
            return state.queries[id] as ResourceState<Resource>;
        }
        return Resources.Empty;
    });
    useEffect(() => {
        if (isEmpty(queryState)) {
            dispatch(query.action(id, criteria) as unknown as AnyAction);
        }
    }, [dispatch, query, queryState, id, criteria]);
    return queryState;
}
