import MoodieDiaryRecordEditForm from "components/moodie/MoodieDiaryRecordEditForm";
import React from "react";

import { Card, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import { setLightness } from "polished";

import OptionsButton from "components-lib/buttons/OptionsButton";
import { prettyTimestamp } from "components/calendar/utils";
import { bindDialog, EditDialog, useDialogState } from "components-lib/layout";
import Markdown from "components-lib/Markdown";
import { MoodieDiaryRecord } from "models";

import { emotionColor as moodieEmotionColor } from "./colors";
import MoodieDiaryRecordDeleteDialog from "./MoodieDiaryRecordDeleteDialog";

const MoodieDiaryRecordPreview = ({
    record
}: Props) => {
    const editDialog = useDialogState();
    const deleteDialog = useDialogState();

    const theme = useTheme();
    const emotionColor = (emotion: string) => {
        emotion = emotion.toLowerCase();
        let color = moodieEmotionColor(emotion, theme.palette.text.primary);
        switch (theme.palette.mode) {
            case "dark":
                return setLightness(0.8, color);

            case "light":
            default:
                return setLightness(0.4, color);
        }
    }

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        editDialog.open();
    }

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        deleteDialog.open();
    }

    return (
        <Card variant="elevation">
            <CardHeader
                title={prettyTimestamp("date", record.timestamp)}
                titleTypographyProps={{
                    fontFamily: "Roboto Mono",
                }}
                subheader={record.emotions.map(emotion => (
                    <Typography key={`moodie-diary-record-emotion-${emotion.toLowerCase()}`}
                                sx={{ color: emotionColor(emotion) }}>
                        #{emotion.toLowerCase()}
                    </Typography>
                ))}
                subheaderTypographyProps={{
                    fontFamily: "Roboto Mono",
                    sx: {
                        display: "inline-flex",
                        gap: 1,
                    },
                }}
                action={
                    <OptionsButton
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                }
                sx={{
                    border: 0,
                    borderBottom: 2,
                    borderColor: "divider",
                    borderStyle: "solid",
                    "& > .MuiCardHeader-content": {
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                    }
                }}
            />
            <CardContent>
                <Markdown text={record.content}/>
            </CardContent>
            <EditDialog title="Edit Diary Record" {...bindDialog(editDialog)}>
                <MoodieDiaryRecordEditForm id={record.id}/>
            </EditDialog>
            <MoodieDiaryRecordDeleteDialog {...bindDialog(deleteDialog)} id={record.id as string}/>
        </Card>
    )
}

interface Props {
    record: MoodieDiaryRecord;
}

export default MoodieDiaryRecordPreview;