import React from "react";

import { Box } from "@mui/material";

import { HeaderPosition, unwrapHeaderPosition } from "components/calendar/PositionGrid/models";

const PositionGridHeader = ({
    axis,
    position,
    children,
}: PositionGridHeaderProps) => {
    const [start, end] = unwrapHeaderPosition(position)
    const gridArea = axis === "y" ? `${start + 1} / 1 / ${end + 2} / 1` : `1 / ${start + 1} / 1 / ${end + 2}`

    return <Box sx={{ gridArea }} children={children}/>
}

export interface PositionGridHeaderProps {
    axis: "y" | "x";
    position: HeaderPosition;
    children?: React.ReactNode;
}


export default PositionGridHeader;


