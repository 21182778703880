import { debounce } from "commons/events/utils";
import React, { useEffect, useRef } from "react";

const CollageItem = ({
    maxHeight,
    children,
}: CollageItemProps) => {
    const ref = useRef<HTMLDivElement>(null);

    let currentHeight: number;

    const resize = debounce(() => {
        if (!ref.current) {
            return;
        }

        const grid = ref.current.parentNode as HTMLDivElement;
        const gridStyle = window.getComputedStyle(grid);
        const rowHeight = parseInt(gridStyle.getPropertyValue("grid-auto-rows"));
        const rowGap = parseInt(gridStyle.getPropertyValue("grid-row-gap"));
        const content = ref.current.firstChild as HTMLDivElement;
        const contentHeight = content.getBoundingClientRect().height;

        // R: row height, G: row gap, C: content height
        // RN + G(N-1) = C <=> N = (C + G) / (R + G)
        let span = Math.ceil((contentHeight + rowGap) / (rowHeight + rowGap));

        if (maxHeight) {
            span = Math.min(span, maxHeight);
        }

        ref.current.style.gridRowEnd = `span ${span}`;

        if (currentHeight !== contentHeight) {
            // make sure to keep adjusting items until their height is unchanged
            currentHeight = contentHeight;
            resize();
        }

    }, 200);

    useEffect(resize);
    useEffect(() => {
        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        }
    });

    return (
        <div ref={ref} style={{ overflow: "auto" }}>
            <div>{children}</div>
        </div>
    );
}

interface CollageItemProps {
    maxHeight?: number;

    children?: React.ReactNode;
}

export default CollageItem;
