import React from "react";
import { useSelector } from "react-redux";

import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from "@mui/material";

export const lightTheme = responsiveFontSizes(createTheme({
    palette: {
        mode: "light",
        background:{
            // paper: "#f1e6fe",
            // paper: "hsl(268deg 20% 98%)",
            paper: "hsl(0 0% 98%)",
        },
        primary: {
            main: "#7C36D1",
        }
    }
}))

export const darkTheme = responsiveFontSizes(createTheme({
    palette: {
        mode: "dark",
        background: {
        },
        primary: {
            main: "#8F6AC6",
        },
    },
}))

const AppTheme: React.FC<Props> = ({ children }) => {
    const { mode } = useSelector((state: any) => state.theme)
    return (
        <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    )
}

interface Props {
    children: React.ReactNode;
}

export default AppTheme;
