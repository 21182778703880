import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, CircularProgress, Typography } from "@mui/material";

import { isPersisted, isReady } from "commons";
import { Mood } from "models";
import { useQuery } from "state";
import { DailyQueries, useDailyMoodOrCreate } from "state/moodie";

import MoodieDailyCalendar from "./MoodieDailyCalendar";
import MoodSelection from "./MoodSelection";


const MoodieDaily = () => {
    const query = useQuery("moodie-daily", DailyQueries.Mood.FetchAll, undefined);
    const dispatch = useDispatch();

    const dailyMood = useDailyMoodOrCreate();
    const [selectedMood, setSelectedMood] = useState<Mood | null>(dailyMood.mood || null);
    useEffect(() => {
        setSelectedMood(dailyMood.mood || null);
    }, [dailyMood.mood]);

    const handleMoodSelected = (mood: Mood | null) => {
        if (mood !== null) {
            dispatch(DailyQueries.Mood.CreateOrUpdate.once({
                ...dailyMood,
                mood,
            }));
        } else if (isPersisted(dailyMood)) {
            dispatch(DailyQueries.Mood.Delete.once(dailyMood.id));
        }
        setSelectedMood(mood);
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4" component="span">
                How are you feeling today?
            </Typography>
            {!isReady(query) && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress/>
                </Box>
            )}
            {isReady(query) && (
                <>
                    <MoodSelection
                        mood={selectedMood}
                        onMoodChanged={handleMoodSelected}
                        iconSize="small"
                    />
                    <MoodieDailyCalendar/>
                </>
            )}
        </Box>
    );
}

export default MoodieDaily;