import React from "react";

import { Box, Typography, TypographyProps } from "@mui/material";

const TimeGridHeader = React.forwardRef(({ children, TypographyProps }: TimeGridHeaderProps, ref) => {
    return (
        <Box ref={ref} sx={{ paddingX: 1 }}>
            <Typography
                {...TypographyProps}
            >
                {children}
            </Typography>
        </Box>
    );
})

export interface TimeGridHeaderProps {
    TypographyProps?: TypographyProps;
    children?: React.ReactNode;
}

export default TimeGridHeader;