import React from "react";
import { useParams } from "react-router-dom";

import { Box, Container, Fab, Typography } from "@mui/material";

import { isReady, resource } from "commons/models";

import { EditIcon } from "components-lib/icons";
import { EditDialog, bindDialog, useDialogState } from "components-lib/layout";
import Markdown from "components-lib/Markdown";
import NoteEditForm from "components/notes/NoteEditForm";
import LoadingSpinner from "components/template/LoadingSpinner";
import { useNote } from "state/notes";


const NotePage = () => {
    const params = useParams();
    const query = useNote(params.noteId as string);

    const editDialog = useDialogState();


    if (!isReady(query)) {
        return (<Container> <LoadingSpinner/></Container>);
    }

    const note = resource(query);

    return (
        <Container>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography variant="h3" sx={{ flexGrow: 1 }}>
                    {note.title}
                </Typography>
            </Box>
            <Markdown text={note.content}/>
            <EditDialog title="Edit Note" {...bindDialog(editDialog)}>
                <NoteEditForm id={note.id}/>
            </EditDialog>
            <Fab color="primary"
                 onClick={editDialog.open}
                 sx={{ m: 0, top: "auto", right: 20, bottom: 20, left: "auto", position: "fixed" }}>
                <EditIcon/>
            </Fab>
        </Container>
    )
}

export default NotePage;