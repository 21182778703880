import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
} from '@mui/material';

import { login } from "state/auth";
import { Visibility, VisibilityOff } from "components-lib/icons";

interface PasswordState {
  value: string;
  showPassword: boolean;
}

const LoginForm = (): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<PasswordState>({
    value: '',
    showPassword: false,
  });

  const dispatch = useDispatch();

  const onLogInButtonClick = () => {
    dispatch(
      login({ email, password: password.value }, () => navigate('/home')) as any
    );
  };

  const onKeyDownClick = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onLogInButtonClick();
    }
  };

  const handleChange =
    (prop: keyof PasswordState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  return (
    <form onKeyDown={(e) => onKeyDownClick(e)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="email"
            placeholder="Email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              sx={{ opacity: '60%' }}
              htmlFor="outlined-adornment-password"
            >
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={password.showPassword ? 'text' : 'password'}
              value={password.value}
              onChange={handleChange('value')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {password.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button variant="contained" onClick={onLogInButtonClick}>
            Log In
          </Button>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Link href="/forgotten-password">Forgot your password?</Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
