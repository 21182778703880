import React from "react";

import { BottomNavigation, BottomNavigationAction, Container } from "@mui/material";

import { LibraryBooksIcon, NoteAddIcon } from "components-lib/icons";
import { bindDialog, EditDialog, useDialogState } from "components-lib/layout";
import MoodieDaily from "components/moodie/MoodieDaily";
import MoodieDiary from "components/moodie/MoodieDiary";
import MoodieDiaryRecordEditForm from "components/moodie/MoodieDiaryRecordEditForm";

const MoodieMainPage = () => {
    const createDialog = useDialogState();

    return (
        <Container sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            marginBottom: 8,
        }}>
            <MoodieDaily/>
            <MoodieDiary/>
            <EditDialog title="Diary Record" {...bindDialog(createDialog)}>
                <MoodieDiaryRecordEditForm/>
            </EditDialog>
            <BottomNavigation
                sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    borderTop: 1,
                    borderTopColor: "divider",
                    borderTopStyle: "solid",
                }}
                value={0}
                onChange={createDialog.open}
            >
                <BottomNavigationAction label="Board" icon={<LibraryBooksIcon/>}/>
                <BottomNavigationAction label="New Note" icon={<NoteAddIcon/>}/>
            </BottomNavigation>
        </Container>
    )
}

export default MoodieMainPage;