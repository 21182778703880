import { Box, BoxProps, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";


const NoteFilter = ({
    value,
    onChange
}: Props) => {
    return (
        <NoteFilterRoot>
            <DatePicker
                label="From"
                onChange={from => onChange({...value, from})}
                value={value.from}
                renderInput={
                    params => <TextField {...params} />
                }/>
            <DatePicker
                label="To"
                onChange={to => onChange({...value, to})}
                value={value.to}
                renderInput={
                    params => <TextField {...params} />
                }/>
        </NoteFilterRoot>
    )
}

const NoteFilterRoot = (props: BoxProps) => {
    return <Box
        {...props}
        sx={{
            display: "flex",
            gap: 1,
            "&>*": {
                flex: "1",
            }
        }}
    />;
}

interface NoteFilterValue {
    search?: string;
    from: Date | null;
    to: Date | null;
}

interface Props {
    value: NoteFilterValue;
    onChange: (value: NoteFilterValue) => void;
}

export default NoteFilter;