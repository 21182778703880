import { ReduxIndex } from "commons/redux";

export const ResourceEmpty = "empty";
export const ResourceRequested = "requested";
export const ResourceReady = "ready";
export const ResourceFailed = "failed";
export type ResourceStatus =
    typeof ResourceEmpty
    | typeof ResourceRequested
    | typeof ResourceReady
    | typeof ResourceFailed;
export type ResourceError = Error | string;

interface EmptyResourceState {
    status: typeof ResourceEmpty;
}

interface RequestedResourceState {
    status: typeof ResourceRequested;
}

interface ReadyResourceState<Resource = {}> {
    status: typeof ResourceReady;
    resource: Resource;
}

interface FailedResourceState {
    status: typeof ResourceFailed;
    error: ResourceError;
}

export type ResourceState<Resource = {}> =
    EmptyResourceState
    | RequestedResourceState
    | ReadyResourceState<Resource>
    | FailedResourceState;
export const isEmpty = <Resource = {}>(state: ResourceState<Resource>): state is EmptyResourceState =>
    state.status === ResourceEmpty;
export const isRequested = <Resource = {}>(state: ResourceState<Resource>): state is RequestedResourceState =>
    state.status === ResourceRequested;
export const isReady = <Resource = {}>(state: ResourceState<Resource>): state is ReadyResourceState<Resource> =>
    state.status === ResourceReady;
export const isFailed = <Resource = {}>(state: ResourceState<Resource>): state is FailedResourceState =>
    state.status === ResourceFailed;
export const resource = <Resource = {}>(state: ReadyResourceState<Resource>): Resource => state.resource;
export const error = (state: FailedResourceState): ResourceError => state.error;
export const Resources = {
    Empty: {
        status: ResourceEmpty,
    } as EmptyResourceState,
    Requested: {
        status: ResourceRequested,
    } as RequestedResourceState
}

export const createResource = <Resource = {}>(resource: Resource): ReadyResourceState<Resource> => ({
    status: ResourceReady,
    resource,
});

export const createError = (error: ResourceError): FailedResourceState => ({
    status: ResourceFailed,
    error,
});

export type ReduxQueryIndex<Resource> = ReduxIndex<ResourceState<Resource>>;