import { New } from "commons/models";

export interface NoteStyle {
    background: string;
    text: string;
}

export interface Note {
    id: string;
    title: string;
    content: string;
    style?: NoteStyle;
    createdAt: Date;
    updatedAt: Date;
}

export const styles: NoteStyle[] = [
    { background: '#a3dba3', text: 'black' },
    { background: '#b8d2db', text: 'black' },
    { background: '#f2cdd3', text: 'black' },
];

export const newNote = (): New<Note> => ({
    title: "",
    content: "",
    createdAt: new Date(),
    updatedAt: new Date(),
});

