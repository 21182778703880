import { useState } from "react";

interface TextEditorController {
    type: "text-editor";

    value: string;
    setValue: (value: string) => void;

    mode: "source" | "preview";
    setMode: (value: "source" | "preview") => void;
    preview: () => void;
    source: () => void;

    selection: [number, number];
    setSelection: (value: [number, number]) => void;
    select: (start: number, end: number) => void;
}

export const useTextEditorState = (
    initialValue: string,
    initialMode: "source" | "preview" = "source",
    initialSelection: [number, number] = [0, 0]
): TextEditorController => {
    const [value, setValue] = useState(initialValue);
    const [mode, setMode] = useState(initialMode);
    const [selection, setSelection] = useState(initialSelection);

    return {
        type: "text-editor",
        value,
        setValue,
        mode,
        setMode,
        preview: () => setMode("preview"),
        source: () => setMode("source"),
        selection,
        setSelection,
        select: (start: number, end: number) => setSelection([start, end]),
    }
}

export const bindTextEditor = (controller: TextEditorController) => ({
    value: controller.value,
    mode: controller.mode,
    selectionStart: controller.selection[0],
    selectionEnd: controller.selection[1],
    onChange: controller.setValue,
    onSource: controller.source,
    onPreview: controller.preview,
    onSelection: controller.select,
})