import { useState } from "react";

interface ComponentHistory<ComponentState> {
    push(state: ComponentState): void;

    undo(): ComponentState;

    redo(): ComponentState;
}


export function useHistory<ComponentState>(initialState: ComponentState): ComponentHistory<ComponentState> {
    const [history, setHistory] = useState<{
        current: number;
        stack: ComponentState[];
    }>({ current: 0, stack: [initialState] });

    return {
        push(state) {
            setHistory(history => {
                return {
                    current: history.current + 1,
                    stack: [...history.stack.slice(0, history.current), state]
                };
            });
        },
        undo() {
            if (history.current === 0) {
                return history.stack[0];
            }
            setHistory({
                current: history.current - 1,
                stack: history.stack,
            });
            return history.stack[history.current - 1];
        },
        redo() {
            if (history.current === history.stack.length - 1) {
                return history.stack[history.stack.length - 1];
            }
            setHistory({
                current: history.current + 1,
                stack: history.stack,
            });
            return history.stack[history.current + 1];
        }
    }
}