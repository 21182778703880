import { useSelector } from "react-redux";

export type MenuVisibility = "open" | "closed";

interface ToggleAction {
    type: "MENU_TOGGLE";
}

interface ShowAction {
    type: "MENU_SHOW";
}

interface HideAction {
    type: "MENU_HIDE";
}

type MenuAction = ToggleAction | ShowAction | HideAction;

export const toggle = (): ToggleAction => {
    return {
        type: "MENU_TOGGLE"
    }
}

export const show = (): ShowAction => {
    return {
        type: "MENU_SHOW"
    }
}

export const hide = (): HideAction => {
    return {
        type: "MENU_HIDE"
    }
}

export interface MenuState {
    visibility: MenuVisibility;
}

export const MenuReducer = (state: MenuState = { visibility: "closed" }, action: MenuAction): MenuState => {
    switch (action.type) {
        case "MENU_TOGGLE":
            return {
                ...state,
                visibility: state.visibility === "open" ? "closed" : "open",
            }

        case "MENU_SHOW":
            return {
                ...state,
                visibility: "open",
            }

        case "MENU_HIDE":
            return {
                ...state,
                visibility: "closed",
            }

        default:
            return state;
    }
}

export const useIsOpen = (): boolean => {
    return useSelector((state: any) => state.menu.visibility === "open");
}

export const useVisibility = (): MenuVisibility => {
    return useSelector((state: any) => state.menu.visibility);
}
