import React from "react";

import { Box } from "@mui/material";

import { CellPosition, unwrapCellPosition } from "components/calendar/PositionGrid/models";

const PositionGridCell = React.forwardRef(({
    position,
    ...other
}: PositionGridCellProps, ref) => {
    const [column, row] = unwrapCellPosition(position);

    return <Box sx={{ gridArea: `${row + 1} / ${column + 1}` }} {...other} ref={ref}/>
})

export interface PositionGridCellProps {
    position: CellPosition;
    children: React.ReactNode;
}

export default PositionGridCell;