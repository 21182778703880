import NoteEditForm from "components/notes/NoteEditForm";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Card, CardContent, CardHeader } from "@mui/material";

import OptionsButton from "components-lib/buttons/OptionsButton";
import { prettyTimestamp } from "components/calendar/utils";
import { bindDialog, DeleteDialog, EditDialog, useDialogState } from "components-lib/layout";
import Markdown from "components-lib/Markdown";
import { Note } from "models";
import { Query } from "state/notes";


const NoteCard = ({ note }: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const editDialog = useDialogState();
    const deleteDialog = useDialogState();

    const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const clickWithinCard = e.currentTarget.contains(e.target as Element);
        if (clickWithinCard && !e.isDefaultPrevented()) {
            navigate(`/notes/note/${note.id}`);
        }
    }

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        editDialog.open();
    }

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        deleteDialog.open();
    }

    const deleteNote = () => {
        dispatch(Query.DeleteNote.once(note.id));
    }


    return (
        <Card
            variant="elevation"
        >
            <CardHeader
                title={note.title}
                titleTypographyProps={{
                    onClick: handleOnClick,
                    sx: {
                        "&:hover": {
                            textDecoration: "underline",
                            cursor: "pointer",
                        }
                    }
                }}
                subheader={prettyTimestamp("datetime", note.createdAt)}
                subheaderTypographyProps={{
                    variant: "caption",
                    fontFamily: "Roboto Mono",
                }}
                action={
                    <OptionsButton
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                }
                sx={{
                    border: 0,
                    borderBottom: 2,
                    borderColor: "divider",
                    borderStyle: "solid",
                }}
            />
            <CardContent>
                <Markdown text={note.content} crop/>
            </CardContent>
            <EditDialog title="Edit Note" {...bindDialog(editDialog)} >
                <NoteEditForm id={note.id}/>
            </EditDialog>
            <DeleteDialog
                title="Delete Note"
                question="Are you sure you want to delete this note?"
                onDelete={deleteNote}
                {...bindDialog(deleteDialog)}
            />
        </Card>
    )
}

interface Props {
    note: Note;
}

export default NoteCard;