import React from "react";
import { useDispatch } from "react-redux";

import { Box, Paper, Typography } from "@mui/material";
import { bindHover, bindPopover, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import HoverPopover from "material-ui-popup-state/HoverPopover";

import { isPersisted } from "commons";
import { Mood, MoodieDailyMood, newDailyMood } from "models";
import { DailyQueries } from "state/moodie";

import { moodGradient } from "./colors";
import MoodSelection from "./MoodSelection";


const MoodieDailyCalendarItem = ({
    date,
    mood,
}: MoodieDailyCalendarItemProps) => {
    const currentMood = mood || newDailyMood(date);
    const dispatch = useDispatch();
    const popupState = usePopupState({
        variant: "popover",
        popupId: `moodie-daily-calendar-item-${date.toISOString()}`
    })
    const handleMoodChange = (newMood: Mood | null) => {
        if (newMood !== null) {
            dispatch(DailyQueries.Mood.CreateOrUpdate.once({
                ...currentMood,
                mood: newMood,
            }));
        } else if (isPersisted(currentMood)) {
            dispatch(DailyQueries.Mood.Delete.once(currentMood.id));
        }
        popupState.close()
    }

    const bgColor = currentMood.mood === undefined ? "background.paper" : moodGradient(currentMood.mood);


    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "2px",
            }}
        >
            <Paper
                sx={{
                    borderRadius: 1,
                    border: 1,
                    borderColor: "divider",
                    width: 16,
                    height: 16,
                    cursor: "pointer",
                    backgroundColor: bgColor,
                    "&:hover": {
                        borderColor: "primary.main",
                    }
                }}
                {...bindTrigger(popupState)}
                {...bindHover(popupState)}
            />
            <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ padding: 1, borderRadius: 1 }}>
                    <Typography>
                        {date.toDateString()}
                    </Typography>
                    <MoodSelection mood={currentMood.mood || null} onMoodChanged={handleMoodChange} iconSize="small"/>
                </Box>
            </HoverPopover>
        </Box>
    )
}


export interface MoodieDailyCalendarItemProps {
    date: Date;
    mood: MoodieDailyMood | null;
}

export default MoodieDailyCalendarItem;