import React from "react";

import { Box } from "@mui/material";

import { Mood } from "models";

import MoodOption from "./MoodOption";

interface MoodConfig {
    mood: Mood;
    caption?: string;
}

export const Moods: MoodConfig[] = [
    { mood: 1, caption: "Very Bad" },
    { mood: 2, caption: "Bad" },
    { mood: 3, caption: "Neutral" },
    { mood: 4, caption: "Good" },
    { mood: 5, caption: "Very Good" },
];

const MoodSelection = ({
    mood,
    onMoodChanged,
    iconSize,
}: MoodSelectionProps) => {
    const handleClick = (option: MoodConfig) => {
        if (option.mood === mood) {
            onMoodChanged(null);
        } else {
            onMoodChanged(option.mood);
        }
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-around",
            gap: 1,
        }}>
            {Moods.map(option =>
                <MoodOption
                    key={option.mood}
                    mood={option.mood}
                    caption={option.caption}
                    selected={option.mood === mood}
                    onSelected={() => handleClick(option)}
                    ButtonProps={{
                        onClick: () => handleClick(option),
                        sx: { flex: "0 0 auto" },
                    }}
                    size={iconSize}
                />
            )}
        </Box>

    )
}

export interface MoodSelectionProps {
    mood: Mood | null;
    onMoodChanged: (mood: Mood | null) => void;
    iconSize?: "small" | "medium" | "large";
}

export default MoodSelection;