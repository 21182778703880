import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";


const Dialog = ({
    ...props
}: MuiDialogProps) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <MuiDialog
            scroll="paper"
            fullScreen={props.fullScreen !== undefined ? props.fullScreen : fullScreen}
            {...props}/>
    )
}

export type DialogProps = MuiDialogProps

export default Dialog;