export interface Command<T = {}> {
    name: string;
    context: string;
    payload: T;
}

export interface CommandListener<TCommand extends Command = Command> {
    (command: TCommand): void;
}

export class CommandManager {

    private listenersByCommand = new Map<string, {
        listener: CommandListener;
        context: string;
    }[]>();

    /**
     *
     * @param command
     */
    trigger(command: Command<any>) {
        const listeners = this.listenersByCommand.get(command.name) || [];
        for (const listener of listeners) {
            if (listener.context === command.context) {
                // only execute listener if the context matches
                listener.listener(command);
            }
        }
    }

    addCommandListener(context: string, command: string, listener: CommandListener) {
        const listeners = this.listenersByCommand.get(command) || [];
        this.listenersByCommand.set(command, [...listeners, { context, listener }]);
    }

    removeCommandListener(context: string, command: string, listener: CommandListener) {
        const listeners = this.listenersByCommand.get(command) || [];
        this.listenersByCommand.set(command, listeners.filter(x => x.context !== command && x.listener !== listener));
    }
}




