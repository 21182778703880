import { deserializer, serializer } from "commons/models";
import { createIndex } from "commons/redux";
import { MoodieDiaryRecord, MoodieEmotion } from "models";

export interface MoodieDiaryRecordState {
    id: string;
    timestamp: string;
    content: string;
    emotions: MoodieEmotion[];
}

const serialize = serializer<MoodieDiaryRecord, MoodieDiaryRecordState>(model => ({
    ...model,
    timestamp: model.timestamp.toISOString(),
}))

const deserialize = deserializer<MoodieDiaryRecord, MoodieDiaryRecordState>(state => ({
    ...state,
    timestamp: new Date(state.timestamp),
}));

const index = createIndex(
    "MOODIE_DAIRY_RECORD",
    serialize,
);

export const DiaryRecordRedux = {
    ...index,
    serialize,
    deserialize,
}

