import { Box, Typography } from "@mui/material";

import { useProfile } from "state/auth";

const GreetingWidget = (): JSX.Element => {
    const profile = useProfile();

    return (
        <Box>
            <Typography variant="subtitle1">
                Good Afternoon {profile?.username} !
            </Typography>
            <Typography variant="subtitle2"> Welcome back </Typography>
        </Box>
    );
};

export default GreetingWidget;