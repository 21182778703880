import React from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"

import {
    Box,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIconProps,
    Toolbar,
    Typography
} from "@mui/material";

import { MenuState } from "state";
import { CloseIcon, HomeIcon, SpaIcon, TextSnippetIcon } from "components-lib/icons";

import { useDevice } from "commons/media";

const MenuLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function MenuLink(
    itemProps,
    ref,
) {
    return <Link ref={ref} component={RouterLink} color="inherit" {...itemProps} role={undefined}/>;
});

interface MenuItem {
    title: string;
    caption?: string;
    link: string;
    icon: React.ComponentType<SvgIconProps>;
}

const MenuItems: MenuItem[] = [
    {
        title: "Home",
        link: "/home",
        icon: HomeIcon,
    },
    {
        title: "Notes",
        caption: "Note keeping at it's best.",
        link: "/notes/board",
        icon: TextSnippetIcon,
    },
    {
        title: "Moodie",
        caption: "Mood tracker",
        link: "moodie",
        icon: SpaIcon,
    },
]

export const AppMenu = () => {
    const dispatch = useDispatch();
    const { desktop } = useDevice();
    const open = MenuState.useIsOpen();

    const onClose = () => {
        dispatch(MenuState.hide() as any);
    }

    // TODO(souperk): customize drawer size and position based on device
    //  size. On large screens drawer should be fixed to the left.
    return (
        <Drawer
            variant={desktop ? "permanent" : "temporary"}
            anchor="left"
            open={open}
            onClose={onClose}
            // sx={{
            //     width: 250,
            //     flexShrink: 0,
            //     '& .MuiDrawer-paper': {
            //         width: 250,
            //         boxSizing: 'border-box',
            //     },
            // }}
        >
            <Box
                component="nav"
                sx={{ width: desktop ? 360 : "100vw" }}
            >
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Menu
                    </Typography>
                    {!desktop && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ ml: 2 }}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    )}
                </Toolbar>
                <Divider/>
                <List>
                    {MenuItems.map(menuItem =>
                        <ListItem
                            key={menuItem.title}
                            disablePadding
                            component={MenuLink}
                            to={menuItem.link}
                            onClick={onClose}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <menuItem.icon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={menuItem.title}
                                    primaryTypographyProps={{
                                        variant: "h4",
                                        component: "span",
                                    }}
                                    secondary={menuItem.caption}
                                    secondaryTypographyProps={{
                                        variant: "caption",
                                        component: "span",
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Box>
        </Drawer>
    )
}

export default AppMenu;