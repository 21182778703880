import React, { useContext, useEffect } from "react";

import { CommandContextProvider } from "commons/command/CommandContextProvider";
import { CommandListener } from "commons/command/Command";
import { CommandContext, CommandManager } from "commons/command/CommandContext";

export function useCommand(command: string, listener: CommandListener, context?: string) {
    const localContext = useContext(CommandContext);
    const currentContext = context || localContext;

    useEffect(() => {
        CommandManager.addCommandListener(currentContext, command, listener);
        return () => {
            CommandManager.removeCommandListener(currentContext, command, listener);
        }
    }, [currentContext, command, listener]);
}

type CommandTrigger<Payload> = Payload extends undefined ? (payload?: Payload) => void : (payload: Payload) => void;

export function useCommandTrigger<Payload = undefined>(name: string, context?: string): CommandTrigger<Payload> {
    const localContext = useContext(CommandContext);
    return ((payload?: Payload) => {
        CommandManager.trigger({
            name,
            context: context || localContext,
            payload: payload as Payload,
        });
    }) as CommandTrigger<Payload>
}

export function withCommandContext<Props>(Component: React.ComponentType<Props>, config: {
    context?: string
} = {}) {
    return (props: Props) => (
        <CommandContextProvider context={config.context}>
            {/* @ts-ignore */}
            <Component {...props}/>
        </CommandContextProvider>
    );
}