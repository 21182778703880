import { useNavigate } from "react-router-dom";


import { Box, styled } from "@mui/material";

import { CircleIcon } from "components-lib/icons";
import { prettyTimestamp } from "components/calendar/utils";
import { CalendarItem } from "models";

const CalendarListingRow = ({
    item
}: Props) => {
    const navigate = useNavigate();
    const handleOnClick = () => {
        navigate(item.link);
    };


    return (
        <CalendarItemWrapper onClick={handleOnClick}>
            <CalendarItemBullet/>
            <Box sx={{ flexGrow: 1, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                {item.title}
            </Box>
            <Box>{prettyTimestamp("compare", item.timestamp, new Date(), { short: true })}</Box>
        </CalendarItemWrapper>
    )
}

const CalendarItemWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),

    padding: theme.spacing(1),

    border: 0,
    borderBottom: 2,
    borderStyle: "solid",

    cursor: "pointer",

    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    }
}));

const CalendarItemBullet = styled(CircleIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}))


interface Props {
    item: CalendarItem;
}

export default CalendarListingRow;