import React, { useState } from "react";

import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    BoxProps,
    Container,
    IconButton,
    TextField
} from "@mui/material";

import { isReady, resource } from "commons/models";

import { bindDialog, Collage, CollageItem, EditDialog, useDialogState } from "components-lib/layout";
import NoteCard from "components/notes/NoteCard";
import NoteEditForm from "components/notes/NoteEditForm";
import NoteFilter from "components/notes/NoteFilter";
import LoadingSpinner from "components/template/LoadingSpinner";

import { useNotes } from "state/notes";

import { FilterAltIcon, LibraryBooksIcon, NoteAddIcon, SearchIcon } from "components-lib/icons";

const NoteBoardPage = () => {
    const createDialog = useDialogState();
    const [search, setSearch] = useState("");
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [from, setFrom] = useState<Date | null>(null);
    const [to, setTo] = useState<Date | null>(null);

    const query = useNotes("board-page", {
        from,
        to,
        search: search,
        orderBy: "updatedAt",
    });

    if (!isReady(query)) {
        return (
            <Container>
                <LoadingSpinner/>
            </Container>
        );
    }

    const notes = resource(query);

    return (
        <>
            <Container sx={{ marginBottom: 8, display: "flex", flexDirection: "column", gap: 2 }}>
                <Toolbar>
                    <TextField
                        value={search}
                        onChange={event => setSearch(event.target.value)}
                        placeholder="Search..." sx={{ flexGrow: 1 }}
                        size="medium"
                        InputProps={{ startAdornment: <SearchIcon/> }}
                    />
                    <IconButton aria-label="show-filters" onClick={() => setFiltersVisible(!filtersVisible)}>
                        <FilterAltIcon/>
                    </IconButton>
                </Toolbar>
                {filtersVisible && <NoteFilter value={{ from, to }} onChange={event => {
                    setFrom(event.from);
                    setTo(event.to);
                }}/>}
                <Box>
                    <Collage minItemWidth="min(80vw, 20rem)">
                        {notes.map(note => (
                            <CollageItem key={note.id}>
                                <NoteCard note={note}/>
                            </CollageItem>
                        ))}
                    </Collage>
                </Box>
                <EditDialog title="Edit Note" {...bindDialog(createDialog)}>
                    <NoteEditForm/>
                </EditDialog>
            </Container>
            <BottomNavigation
                sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    borderTop: 1,
                    borderTopColor: "divider",
                    borderTopStyle: "solid",
                }}
                value={0}
                onChange={() => createDialog.open()}
            >
                <BottomNavigationAction label="Board" icon={<LibraryBooksIcon/>}/>
                <BottomNavigationAction label="New Note" icon={<NoteAddIcon/>}/>
            </BottomNavigation>
        </>
    )
}


const Toolbar = (props: BoxProps) => {
    return <Box {...props} sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
    }}/>;
}

export default NoteBoardPage;