import CalendarListing from "components/calendar/CalendarListing";
import { useToday } from "components/calendar/utils";
import { useState } from 'react'

import { Box, Container, TextField } from "@mui/material";
import { StaticDatePicker } from '@mui/x-date-pickers'


const CalendarPage = () => {
    const today = useToday();
    const [date, setDate] = useState<Date | null>(today);

    return (
        <Container>
            <Box>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={date}
                    onChange={(newValue) => {
                        setDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Box>
            {date && <CalendarListing date={date}/>}
        </Container>
    )
}

export default CalendarPage;