import React from "react";

import clsx from 'clsx';

import { default as composeClasses } from "@mui/utils/composeClasses";
import { Box, styled, useThemeProps } from "@mui/material";

import { getCollageUtilityClass } from "components-lib/layout/Collage/utils";

const useUtilityClasses = (classes: Record<string, string> | undefined) => {
    const slots = {
        root: ['root'],
    };

    return composeClasses(slots, getCollageUtilityClass, classes);
};

const Collage = React.forwardRef((inProps: CollageProps, ref) => {
    const props = useThemeProps({
        props: inProps,
        name: 'BlueMercuryCollage',
    });

    const { className, ...other } = props;
    const classes = useUtilityClasses(props.classes);

    return (
        <CollageRoot
            className={clsx(classes.root, className)}
            ref={ref}
            {...other}
        />
    );
})

interface CollageProps {
    children: React.ReactNode;
    classes?: Record<string, string>;
    className?: string;
    maxColumns?: number;
    minItemWidth?: string | number;
}

const CollageRoot = styled(Box, {
    name: "BlueMercuryCollage",
    slot: "Root",
    shouldForwardProp: prop => prop !== "minItemWidth",
    overridesResolver: (props: CollageProps, styles) => styles.root,
})<CollageProps>(({ theme, minItemWidth }) => {
    if (typeof minItemWidth === "number") {
        minItemWidth = minItemWidth + "px";
    }
    return {
        display: "grid",
        gridGap: theme.spacing(1),
        gridTemplateColumns: `repeat(auto-fill, minmax(${minItemWidth || "16rem"}, 1fr))`,
        gridAutoRows: theme.spacing(2),
        alignContent: "center",
        boxSizing: "border-box",
        overflow: "auto",
    }
})


export default Collage;
