export function randomKey() {
    const length = 32;
    const characters = '012345689ABCDEF';
    let result = '';
    for (let i = 0; i < (length); i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
        if (i % 4 === 0 && i > 0) {
            result += "-";
        }
    }

    return result;
}

export function random(low: number = 0, high: number = 1): number {
    if (low > high) {
        return random(high, low);
    }
    return low + Math.random() * (high - low);
}

export function randomInt(low: number = 0, high: number = 1): number {
    return Math.floor(random(low, high));
}

export function textSearch(text: string, query: string) {
    const terms = query.split(" ").map(x => x.toLowerCase());
    const processedText = text.toLowerCase();
    return terms.reduce((acc, term) => acc && processedText.includes(term), true);
}

export function matchDate(a: Date, b: Date) {
    return a.getFullYear() === b.getFullYear()
        && a.getMonth() === b.getMonth()
        && a.getDate() === b.getDate();
}

