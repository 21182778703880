import {
    Box,
    Container,
    styled,
} from '@mui/material';

import AppsWidget from "components/AppsWidget";
import CalendarListing from "components/calendar/CalendarListing";
import CalendarTodayWidget from "components/calendar/CalendarTodayWidget";
import { useToday } from "components/calendar/utils";
import GreetingWidget from "components/GreetingWidget";

const HomePage = (): JSX.Element => {
    const today = useToday();
    return (
        <HomePageRoot>
            <GreetingWidget/>
            <Box>
                <CalendarTodayWidget date={today}/>
                <CalendarListing date={today}/>
            </Box>
            <AppsWidget/>
        </HomePageRoot>
    );
};

const HomePageRoot = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
}))


export default HomePage;
