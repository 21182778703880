import React from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { useCommand } from "commons";
import { MarkdownEditor, bindTextEditor, useTextEditorState } from "components-lib/input";
import { DiaryQueries, useDiaryRecordOrCreate } from "state/moodie";

const MoodieDiaryRecordEditForm = ({
    id
}: Props) => {
    const dispatch = useDispatch();
    const record = useDiaryRecordOrCreate(id);
    const contentState = useTextEditorState(record.content);
    const { value: content, setValue: setContent } = contentState;

    useCommand("save", _ => {
        dispatch(DiaryQueries.Record.CreateOrUpdate.once({
            ...record,
            content: content,
        }));
    });

    useCommand("reset", _ => setContent(record.content));

    useCommand("delete", _ => {
        if (id) {
            dispatch(DiaryQueries.Record.Delete.action(id, id));
        }
    });

    return (
        <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto", flex: 1 }}>
            {/* FIXME(souperk): without margin-top: 6px, title label is partially hidden
                        figure out the problem, until then applying this hack. */}
            <MarkdownEditor {...bindTextEditor(contentState)} flex/>
        </Box>
    );
}

interface Props {
    id?: string;
}

export default MoodieDiaryRecordEditForm;
