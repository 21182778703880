import MarkdownIt from "markdown-it";
import Token from "markdown-it/lib/token";


interface MarkdownItAstNode {
    tree: true;
    nodeType: string | null,
    openNode: Token,
    closeNode: Token,
    children: (MarkdownItAstNode | Token)[];
}

function genTreeNode(token: Token | null): MarkdownItAstNode {
    return {
        tree: true,
        nodeType: token?.type?.replace('_open', '') || null,
        openNode: token,
        closeNode: null,
        children: []
    } as any;
}


function makeAst(tokens: Token[]) {

    // dummy root node
    const rootNode = genTreeNode(null);
    let curr = rootNode;
    const stack: MarkdownItAstNode[] = [];
    tokens.forEach(function (tok, idx) {
        let tmp;
        if (tok.nesting === 1) {
            tmp = genTreeNode(tok);
            curr.children.push(tmp);
            stack.push(curr);
            curr = tmp;
        } else if (tok.nesting === -1) {
            curr.closeNode = tok;
            if (!stack.length) throw new Error('AST stack underflow.');
            tmp = stack.pop() as MarkdownItAstNode;
            // TODO: check whether the close node corresponds to the one it opens
            // curr = stack[stack.length - 1];
            curr = tmp;
        } else if (tok.nesting === 0) {
            curr.children.push(tok);
        } else {
            throw new Error('Invalid nesting level found in token index ' + idx + '.');
        }
    });

    if (stack.length !== 0)
        throw new Error('Unbalanced block open/close tokens.');

    return rootNode.children;
}

type VisitorFn<Result = void> = (node: MarkdownItAstNode | Token) => Result;

interface WalkOptions {
    before?: VisitorFn;
    after?: VisitorFn;
    prune?: VisitorFn<boolean>;
}

const isNode = (node: MarkdownItAstNode | Token): node is MarkdownItAstNode => "tree" in node && node.tree;
const isToken = (node: MarkdownItAstNode | Token): node is Token => !("tree" in node) || !node.tree;

function walkAST(nodes: (MarkdownItAstNode | Token)[], options: WalkOptions) {
    for (const node of nodes) {
        if (options.prune && !options.prune(node)) {
            continue;
        }

        if (options.before) {
            options.before(node);
        }

        if ("tree" in node && node.tree) {
            if (node.children) {
                walkAST(node.children, options);
            }
        }

        if (options.after) {
            options.after(node);
        }
    }
}

export function renderHTML(raw: string, options: { crop?: boolean } = {}) {
    // TODO(souperk): add support for plugins
    // TODO(souperk): understand if it's ok to re-render
    const md = new MarkdownIt();
    if (options.crop) {
        const env = {};
        let tokens = md.parse(raw, env);

        const ast = makeAst(tokens);

        const selected: Token[] = [];
        walkAST(ast, {
                before: node => {
                    if (isToken(node)) {
                        selected.push(node);
                    }

                    if (isNode(node)) {
                        selected.push(node.openNode);
                    }
                },
                after: node => {
                    if (isNode(node)) {
                        selected.push(node.closeNode);
                    }
                },
                prune: node => {
                    if (isToken(node)) {
                        return true;
                    }

                    if (isNode(ast[0]) && ast[0].nodeType === "heading") {
                        if (isNode(ast[1]) && ast[1].nodeType === "paragraph" && node === ast[1]) {
                            return true;
                        }
                        if (node.nodeType === "heading") {
                            return node.openNode.tag === ast[0].openNode.tag
                                && ast.filter(x => isNode(x) && x.nodeType === "heading").indexOf(node) <= 6;
                        }
                    }
                    if (isNode(ast[0]) && (ast[0].nodeType === "ordered_list" || ast[0].nodeType === "bullet_list")) {
                        if (node.nodeType === "ordered_list" || node.nodeType === "bullet_list") {
                            return node.openNode.level === 0 && ast.indexOf(node) <= 10;
                        }
                    }

                    const index = ast.indexOf(node);
                    return index === -1 || index === 0;
                },
            }
        )

        return md.renderer.render(selected, {}, env);
    }

    return md.render(raw);
}

function wrap(prefix: string, suffix?: string) {
    return (text: string) => {
        if (suffix === undefined) {
            suffix = prefix;
        }

        if (text.startsWith(prefix) && text.endsWith(suffix)) {
            return text.substring(prefix.length, text.length - suffix.length);
        } else {
            return prefix + text + suffix;
        }
    }
}

function linesSelection(text: string, currentStart: number, currentEnd: number): [number, number] {
    // find start of first line within selection
    let selectionStart = text.substring(0, currentStart).lastIndexOf("\n");
    if (selectionStart === -1) {
        selectionStart = 0;
    } else {
        // selectionStart should point to the 1st character within the line
        selectionStart += 1;
    }

    // find end of last lien within selection
    let selectionEnd = currentEnd;
    const index = text.substring(selectionEnd).indexOf("\n");
    if (index === -1) {
        selectionEnd = text.length;
    } else {
        selectionEnd = selectionEnd + index;
    }

    return [selectionStart, selectionEnd];
}

function prefix(prefix: string, mode: "add" | "remove" | "toggle" = "toggle") {
    return (text: string) => {
        const lines = text.split("\n");
        const isQuoted = lines.findIndex(x => !x.startsWith(prefix)) === -1;

        if (isQuoted && (mode === "remove" || mode === "toggle")) {
            // remove 1st character (which is ">" )
            return lines.map(x => x.substring(prefix.length)).join("\n");
        } else if (mode === "add" || mode === "toggle") {
            // append decorator to each line
            return lines.map(x => prefix + x).join("\n");
        } else {
            return text;
        }
    }
}

interface OperationOptions {
    displacement?: "start" | "end" | "select";

    replace(text: string): string;

    selection?(text: string, start: number, end: number): [number, number];
}

function operation(options: OperationOptions) {
    return (element: HTMLTextAreaElement) => {
        let selectionStart = element.selectionStart || 0;
        let selectionEnd = element.selectionEnd || 0;

        if (options.selection) {
            [selectionStart, selectionEnd] = options.selection(
                element.value,
                selectionStart,
                selectionEnd,
            );
        }

        if (selectionStart === selectionEnd) {
            return;
        }

        const selectedText = element.value.substring(
            selectionStart,
            selectionEnd
        );

        const replaceText = options.replace(selectedText);
        const finalText = element.value.substring(0, selectionStart)
            + replaceText
            + element.value.substring(selectionEnd);

        const displacement = replaceText.length - selectedText.length;

        if (options.displacement === "select" || element.selectionStart !== element.selectionEnd) {
            // if there is a selection from the user, modify it to contain the new text
            selectionEnd += displacement;
        } else if (options.displacement === "start") {
            selectionStart = (element.selectionStart || 0) + displacement;
            selectionEnd = (element.selectionStart || 0) + displacement;
        } else if (options.displacement === "end") {
            selectionStart = (element.selectionEnd || 0) + displacement;
            selectionEnd = (element.selectionEnd || 0) + displacement;
        }

        return {
            text: finalText,
            selectionStart,
            selectionEnd,
        }
    }
}

export type Operation = ReturnType<typeof operation>;
export const Operations = {
    Indent: operation({
        replace: prefix("   ", "add"),
        selection: linesSelection,
        displacement: "start",
    }),

    DeIndent: operation({
        replace: prefix("   ", "remove"),
        selection: linesSelection,
        displacement: "start",
    }),

    Bold: operation({ replace: wrap("**") }),
    Italic: operation({ replace: wrap("*") }),

// TODO(souperk): properly implement code action
//  1. for inline use single quotes
//  2. for block use three quotes
//  3. on blocks minimize selection to non-empty lines
//     Code: operation({ replace: wrap("\n```\n") }),
    Code: operation({
        selection: linesSelection,
        replace(text: string): string {
            if (text.startsWith("```\n") && text.endsWith("```\n")) {
                // remove first and last line
                const lines = text.split("\n");
                return lines.slice(1, lines.length - 2).join("\n");
            } else {
                return "```\n" + text + "\n```\n";
            }
        },
        displacement: "select",
    }),

    BlockQuotes: operation({
        replace: prefix(">"),
        selection: linesSelection,
    }),

    OrderedList: operation({
        replace: prefix("1. "),
        selection: linesSelection,
    }),
    UnorderedList: operation({
        replace: prefix("* "),
        selection: linesSelection,
    }),
}