import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, TextField } from "@mui/material";

import { useCommand } from "commons";
import { bindTextEditor, useTextEditorState, MarkdownEditor } from "components-lib/input";
import { deleteNote, Query, useNoteOrCreate } from "state/notes";

const NoteEditForm = ({
    id
}: Props) => {
    const dispatch = useDispatch();
    const note = useNoteOrCreate(id);
    const [title, setTitle] = useState(note.title);
    const contentEditor = useTextEditorState(note.content);

    useCommand("save", _ => {
        dispatch(Query.CreateOrUpdateNote.once({
            ...note,
            title: title,
            content: contentEditor.value,
            updatedAt: new Date(),
        }));
    });

    useCommand("reset", _ => {
        setTitle(note.title);
        contentEditor.setValue(note.content);
    })

    useCommand("delete", _ => {
        if (id) {
            dispatch(deleteNote(id) as any);
        }
    });

    return (
        <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto", flex: 1 }}>
            {/* FIXME(souperk): without margin-top: 6px, title label is partially hidden
                    figure out the problem, until then applying this hack. */}
            <TextField
                value={title}
                onChange={event => setTitle(event.target.value)}
                label="Title"
                sx={{ m: 1 }}
            />
            <MarkdownEditor {...bindTextEditor(contentEditor)} flex/>
        </Box>
    );
}

interface Props {
    id?: string;
}

export default NoteEditForm;