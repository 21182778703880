import { useState } from "react";

export interface DialogController {
    type: "dialog";

    visible: boolean;
    setVisible: (value: boolean) => void;
    open: () => void;
    close: () => void;
}


export const useDialogState = (
    initialState: boolean = false,
): DialogController => {
    const [visible, setVisible] = useState(initialState);

    return {
        type: "dialog",
        visible,
        setVisible,
        open: () => setVisible(true),
        close: () => setVisible(false),
    }
}

export const bindDialog = (controller: DialogController) => ({
    open: controller.visible,
    onClose: controller.close,
});