import React from "react";

import {
    Box,
    Button,
    ButtonProps,
    SvgIconProps, Theme, Typography,
    useTheme
} from "@mui/material";
import { SxProps } from "@mui/system";
import { setLightness } from "polished";

import {
    CircleIcon,
    SentimentDissatisfiedIcon,
    SentimentNeutralIcon, SentimentSatisfiedIcon,
    SentimentVeryDissatisfiedIcon, SentimentVerySatisfiedIcon
} from "components-lib/icons";
import { Mood } from "models";

import { moodGradient } from "./colors";


const SentimentIcons: React.ComponentType<SvgIconProps>[] = [
    SentimentVeryDissatisfiedIcon,
    SentimentDissatisfiedIcon,
    SentimentNeutralIcon,
    SentimentSatisfiedIcon,
    SentimentVerySatisfiedIcon,
];
const MoodOption = ({
    mood,
    caption,
    selected,
    onSelected,
    ButtonProps: { sx: buttonOverridesSx = {}, ...iconButtonProps } = {},
    size = "large",
}: MoodOptionsProps) => {
    const theme = useTheme();
    const color = moodGradient(mood)
    const Icon = SentimentIcons[mood - 1];

    let baseButtonSx: SxProps<Theme> = {
        borderRadius: 2,
        borderColor: "divider",
        borderWidth: 2,
        borderStyle: "solid",
        minWidth: "unset",
    };
    let foregroundSx: SxProps = {
        color: "black",
    };
    let backgroundSx: SxProps = {};
    let captionSx: SxProps = {}
    if (theme.palette.mode === "dark") {
        baseButtonSx = {
            ...baseButtonSx,
            color: setLightness(0.6, color),
            backgroundColor: theme.palette.background.default,
            borderColor: selected ? setLightness(0.6, color) : "divider",
            // enable hover only for devices that support hover (i.e. no touch devices)
            "@media (hover: hover)": {
                "&:hover": {
                    borderColor: setLightness(0.6, color),
                },
            },
        }
        backgroundSx = {
            color: theme.palette.background.default,
        }
        foregroundSx = {
            color: setLightness(0.6, color),
        }
        captionSx = {
            ...captionSx,
            color: setLightness(0.6, color),
        }
    } else /* light mode or default */ {
        baseButtonSx = {
            ...baseButtonSx,
            color: theme.palette.getContrastText(setLightness(0.8, color)),
            backgroundColor: setLightness(0.9, color),
            borderColor: selected ? "primary.main" : "divider",
            "&:hover": {
                backgroundColor: setLightness(0.9, color),
            },
        }
        backgroundSx = {
            ...backgroundSx,
            color: "#ffe000",
        }
        foregroundSx = {
            ...foregroundSx,
        }
        captionSx = {
            ...captionSx,
        }
    }

    const buttonSx = {
        ...baseButtonSx,
        ...buttonOverridesSx,
    }


    const handleClick = () => {
        onSelected();
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}
        >
            <Button
                {...iconButtonProps}
                sx={buttonSx}
                onClick={handleClick}
            >
                <Box sx={{
                    position: "relative",
                    width: size === "small" ? "1rem" : "2.1875rem",
                    height: size === "small" ? "1rem" : "2.1875rem",
                    "& > .MuiSvgIcon-root": {
                        position: "absolute",
                        maxWidth: "100%",
                        margin: "auto",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    },
                }}>
                    <CircleIcon fontSize={size} sx={backgroundSx}/>
                    <Icon fontSize={size} sx={foregroundSx}/>
                </Box>
            </Button>
            <Typography variant="caption" component="span" sx={captionSx}>
                {caption}
            </Typography>
        </Box>
    )
}

export interface MoodOptionsProps {
    mood: Mood;
    caption?: string;
    selected?: boolean;
    onSelected: () => void;

    ButtonProps?: ButtonProps;
    size?: "small" | "medium" | "large";
}

export default MoodOption;